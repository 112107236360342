import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Icon from "../mbapp-icons/mbapp-icon";

import "./mbapp-modal.css";

/**
 * Cuadro modal
 *
 * @version 1.0.0
 *
 */
const MbappModal = (props) => {
  const handleClose = () => {
    props.onClose && props.onClose();
  };

  // Volver el scroll arriba cuando se muestra el modal
  const myRef = useRef(null);
  useEffect(() => {
    executeScroll();
  }, [props.isVisible]);

  const executeScroll = () => myRef.current.scrollTo(0, 0);

  return (
    <div
      className={`mbapp-modal ${props.isVisible ? "mbapp-modal__show" : ""}`}
    >
      <div className="mbapp-modal--overlay"></div>
      {props.children && (
        <div className="mbapp-modal--container">
          <div className="mbapp-modal--container--content" ref={myRef}>
            <div className="mbapp-modal--container--content--header">
              <div>
                <h3 className="mbapp-modal--container--content--titulo">
                  {props.titulo}
                </h3>
              </div>
              <div>
                <button
                  className="btn btn-action hint--bottom"
                  aria-label="Cerrar"
                  type="button"
                  onClick={() => handleClose()}
                >
                  <Icon name="close" size={22} />
                </button>
              </div>
            </div>
            {props.children}
            <div className="mbapp-modal--container--content--footer">
              <button
                type="button"
                className={`btn btn-default btn-primary btn-${props.type}`}
                onClick={props.onAccept}
                disabled={props.acceptDisabled ? "true" : null}
              >
                {props.botonAceptarText}
              </button>
              <button
                className="btn btn-default"
                type="button"
                onClick={() => handleClose()}
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

MbappModal.propTypes = {
  /** Mostrar el modal */
  isVisible: PropTypes.bool.isRequired,
  /** Titulo del modal */
  titulo: PropTypes.string,
  /** Tipo de modal */
  type: PropTypes.oneOf(["normal", "warning"]),
  /** Mostrar el modal */
  botonAceptarText: PropTypes.string,
  /** Manejador del aceptar */
  onAccept: PropTypes.func.isRequired,
  /** Manejador del cancelar */
  onClose: PropTypes.func.isRequired,
};

MbappModal.defaultProps = {
  isVisible: false,
  type: "normal",
  titulo: "",
  botonAceptarText: "Aceptar",
  onAccept: null,
  onClose: null,
};

export default MbappModal;
