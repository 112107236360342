import React from "react";
import PropTypes from "prop-types";

/**
 * Component description
 *
 * @see More info https://logico.ar/
 * @version 1.0.0
 *
 */
const MbappSystemNotification = ({ name }) => {
  function notification() {
    Notification.requestPermission(function (result) {
      if (result === "denied") {
        console.log("Permission wasn't granted. Allow a retry.");
        return;
      } else if (result === "default") {
        console.log("The permission request was dismissed.");
        return;
      }
      // Hacer algo con el permiso concedido.
    });
  }
  notification();
  return <></>;
};

MbappSystemNotification.propTypes = {
  /** Description */
  name: PropTypes.string.isRequired,
};

MbappSystemNotification.defaultProps = {
  name: "",
};

export default MbappSystemNotification;
