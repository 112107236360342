import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Icon from "../mbapp-icons/mbapp-icon";
import Toolbar from "../mbapp-147-toolbar/mbapp-147-toolbar";
import Dropdown from "../mbapp-dropdown/mbapp-dropdown";
import Button from "../mbapp-button/mbapp-button";
import Reclamo from "../mbapp-147-reclamo-item/mbapp-147-reclamo-item";
import ReclamoInfo from "../mbapp-147-reclamo-info/mbapp-147-reclamo-info";
import { Link } from "react-router-dom";
import axios from "axios";
import { useQuery } from "react-query";
import { getUserData } from "../../utils/utils.js";
import Item from "../mbapp-menu-item/mbapp-menu-item";
import Permission from "../mbapp-147-permisos/mbapp-147-permisos";

import Loading from "../mbapp-loading/mbapp-loading";
import ErrorPage from "../mbapp-147-reclamo-info/mbapp-147-reclamo-info";

/**
 * Muestra el listado de reclamos
 *
 * @version 1.0.0
 *
 */
const Mbapp147ListadoReclamo = ({
  query,
  client,
  onEstadoChange,
  verAbiertos,
  verCerrados,
  verEspera,
}) => {
  const { isLoading, error, data, refetch } = useQuery(
    "listadoReclamos",
    () =>
      axios(
        `${process.env.REACT_APP_API_147_ENDPOINT}/reclamos/buscar${query}`,
        {
          headers: {
            Authorization: `Bearer ${getUserData("token")}`,
          },
        }
      ),
    {
      refetchInterval: 10000,
      refetchIntervalInBackground: true,
      onError(err) {
        if (err.toString().includes("401")) {
          window.location.href = "/";
        }
      },
    }
  );

  useEffect(() => {
    refetch(true);
    client.invalidateQueries("listadoReclamos");
    console.log(query);
  }, [refetch, client, query]);

  if (isLoading) return <Loading color="var(--color-verde)" />;

  if (error) {
    return (
      <ErrorPage
        text={"Algo salió mal: " + error.message}
        subtext={"Vuelva a intentarlo más tarde"}
        icono="cloudLightning"
      />
    );
  }

  const renderReclamos = (listado) => {
    if (listado.data && listado.data.length > 0) {
      return (
        <div className="app147--panel--reclamos--container">
          <div>
            {listado.data.map((reclamo) => (
              <Link key={reclamo._id} to={`/reclamos/detalle/${reclamo._id}`}>
                <Reclamo reclamo={reclamo} />
              </Link>
            ))}
          </div>
          {listado.data.length > 10 && (
            <button className="btn btn-loadmore">cargar más...</button>
          )}
        </div>
      );
    } else {
    }
    return (
      <ReclamoInfo
        icono="thumbsUp"
        text="Nada para ver"
        subtext="No se encontraron reclamos"
      />
    );
  };

  return (
    <div>
      <Toolbar label="Eficiencia -">
        <div>
          <Dropdown className="toolbar147--dropdown" text="Estado" width="9em">
            <ul>
              <Item
                text="Abierto"
                isCheckbox
                checkState={verAbiertos}
                onClick={() => onEstadoChange("ABIERTO", !verAbiertos)}
              />
              <Item
                text="Cerrado"
                isCheckbox
                checkState={verCerrados}
                onClick={() => onEstadoChange("CERRADO", !verCerrados)}
              />
              <Item
                text="En espera"
                isCheckbox
                checkState={verEspera}
                onClick={() => onEstadoChange("ESPERA", !verEspera)}
              />
            </ul>
          </Dropdown>
        </div>

        <div>
          <Dropdown className="toolbar147--dropdown" text="Filtros">
            <ul></ul>
          </Dropdown>
        </div>

        <div>
          <Permission canView={["O", "U"]}>
            <Link
              to="/reclamos/nuevo"
              className="hint--bottom toolbar147--nuevo--container"
              aria-label="Generar reclamo"
            >
              <Button className="toolbar147--nuevo btn btn-small">
                <Icon
                  name="plus"
                  size={15}
                  className="toolbar147--button--icon"
                />
                Nuevo
              </Button>
            </Link>
          </Permission>
        </div>
      </Toolbar>

      <Permission canView={["O", "U"]}>
        <Link to="/reclamos/nuevo">
          <button className="app147--boton--nuevo__flotando">
            <Icon name="plus" size="30" />
          </button>
        </Link>
      </Permission>
      {renderReclamos(data)}
    </div>
  );
};

Mbapp147ListadoReclamo.propTypes = {
  /** Description */
  query: PropTypes.string,
};

Mbapp147ListadoReclamo.defaultProps = {
  query: "",
};

export default Mbapp147ListadoReclamo;
