import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Combo from "../mbapp-combobox/mbapp-combobox";
import axios from "axios";
import { getUserData } from "../../utils/utils.js";
import "./mbapp-organigrama.css";

/**
 * Selector de áreas municipales
 *
 * @version 1.0.0
 */
const MbappOrganigrama = ({
  label,
  value,
  valueTipoReclamo,
  placeholder,
  required,
  getAreaSeleccionada,
  getTipoReclamo,
  errorState,
}) => {
  const [listaSecretarias, setListaSecretarias] = useState(null);
  const [listaDirecciones, setListaDirecciones] = useState(null);
  const [listaOficinas, setListaOficinas] = useState(null);
  const [selectorEsVisible, setSelectorEsVisible] = useState(false);
  const [listaTipos, setListaTipos] = useState(null);

  const [areaInterna, setAreaInterna] = useState({
    secretaria: { id: 1, text: "GOBIERNO" },
    direccion: { id: 0, text: "N/A" },
    oficina: { id: 0, text: "N/A" },
  });

  const [areaSeleccionada, setAreaSeleccionada] = useState(
    value
      ? value
      : {
          id: 0,
          nombre: placeholder,
        }
  );

  const [tipoReclamo, setTipoReclamo] = useState(
    valueTipoReclamo
      ? valueTipoReclamo
      : {
          id: 0,
          nombre: "N/A",
        }
  );

  const [firstRender, setFirstRender] = useState(true);

  /**
   * Carga los datos del combo según el nivel y la dependencia en el organigrama
   */
  const LoadNivel = (nivel, depende) => {
    return new Promise((resolve, reject) => {
      let url = `${process.env.REACT_APP_API_CORE_ENDPOINT}/organigrama/area/nivel/${nivel}`;
      if (depende) {
        url = url + `?depende=${depende}`;
      } else if (nivel === 3) {
        // Si se elige N/A en secretarias, mostrar
        resolve([]); // nada en el nivel de oficina.
      }

      let request = {
        method: "GET",
        headers: {
          key: process.env.REACT_APP_CORE_KEY, // Cambiar en producción
        },
        mode: "cors",
        cache: "default",
      };

      fetch(url, request)
        .then((response) => response.json())
        .then((apiResponse) => {
          if (apiResponse) {
            resolve(apiResponse);
          }
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  };

  /**
   * Actualiza las direcciones y las oficinas
   */
  function UpdateSecretaria(depende) {
    LoadNivel(2, depende).then((res) => {
      setListaDirecciones(res);
      LoadNivel(3, res[0]?.id).then((resOficinas) =>
        setListaOficinas(resOficinas)
      );
    });
  }

  // Primera carga de combos, los valores son estáticos
  if (firstRender) {
    setFirstRender(false);
    LoadNivel(1).then((res) => setListaSecretarias(res));
    LoadNivel(2, 1).then((res) => setListaDirecciones(res));
    LoadNivel(3, 0).then((res) => setListaOficinas(res));
  }

  /*
   * Maneja el estado cuando se cambia de selección los combos
   * @seleccion: El valor del item seleccionad
   * @nivel: El nivel del combo
   */
  const handleAreaSeleccionada = (seleccion, nivelModificado) => {
    switch (nivelModificado) {
      case 1:
        actualizarAreaInterna("secretaria", seleccion);
        actualizarAreaInterna("direccion", { id: 0, text: "N/A" });
        actualizarAreaInterna("oficina", { id: 0, text: "N/A" });
        UpdateSecretaria(seleccion.id);
        break;
      case 2:
        actualizarAreaInterna("direccion", seleccion);
        actualizarAreaInterna("oficina", { id: 0, text: "N/A" });
        LoadNivel(3, seleccion.id).then((res) => setListaOficinas(res));
        break;
      case 3:
        actualizarAreaInterna("oficina", seleccion);
        break;
      default:
        break;
    }
  };

  const actualizarAreaInterna = (field, val) => {
    setAreaInterna((prevState) => ({
      ...prevState,
      [field]: val,
    }));
  };

  const handleAceptar = () => {
    setSelectorEsVisible(false);
    if (areaInterna.oficina.text !== "N/A") {
      setAreaSeleccionada(areaInterna.oficina);
      return;
    }

    if (areaInterna.direccion.text !== "N/A") {
      setAreaSeleccionada(areaInterna.direccion);
      return;
    }

    setAreaSeleccionada(areaInterna.secretaria);
    return;
  };

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API_147_ENDPOINT}/tipo_reclamo/area/${areaSeleccionada.id}`,
        {
          headers: { Authorization: `Bearer ${getUserData("token")}` },
        }
      )
      .then((data) => {
        console.log(data.data);
        setListaTipos(data.data);
      })
      .catch((err) => {});
  }, [areaSeleccionada]);

  // Levantar el estado
  getAreaSeleccionada(areaSeleccionada);

  // Levantar el estado
  getTipoReclamo(tipoReclamo);

  return (
    <div className="mbapp-organigrama">
      <div className="inputGroup">
        <button
          className="mbapp-organigrama--launcher"
          type="button"
          onClick={() => setSelectorEsVisible(true)}
          style={{ borderColor: errorState ? "red" : null }}
        >
          {areaSeleccionada.nombre}
          <label>{(required ? "* " : "") + label}</label>
        </button>
        <small className="textbox--error-text">
          {errorState && errorState}
        </small>
      </div>
      <div
        className={`mbapp-organigrama--selector ${
          selectorEsVisible ? "mbapp-organigrama--selector__show" : null
        }`}
      >
        <Combo
          name="secretaria"
          text="Secretaría"
          items={listaSecretarias}
          textFieldName="nombre"
          onChange={(value) => handleAreaSeleccionada(value, 1)}
        />
        <Combo
          name="direccion"
          text="Dirección"
          items={listaDirecciones}
          addNA={true}
          textFieldName="nombre"
          onChange={(value) => handleAreaSeleccionada(value, 2)}
        />
        <Combo
          name="oficina"
          text="Oficina"
          items={listaOficinas}
          addNA={true}
          textFieldName="nombre"
          onChange={(value) => handleAreaSeleccionada(value, 3)}
        />
        <div className="mbapp-organigrama--selector--footer">
          <button
            className="btn btn-default btn-primary"
            type="button"
            onClick={handleAceptar}
          >
            Seleccionar
          </button>
          <button
            className="btn btn-default"
            type="button"
            onClick={() => setSelectorEsVisible(false)}
          >
            Cancelar
          </button>
        </div>
      </div>
      <Combo
        name="tipoReclamo"
        text="Tipo de reclamo"
        items={listaTipos}
        textFieldName="nombre"
        onChange={(val) => setTipoReclamo(val)}
        value={tipoReclamo.nombre}
        addNA
      />
    </div>
  );
};
MbappOrganigrama.propTypes = {
  /** Texto de la etiqueta */
  label: PropTypes.string.isRequired,
  /** Texto cuando no se ha seleccionado nada */
  placeholder: PropTypes.string,
  /** Texto cuando no se ha seleccionado nada */
  required: PropTypes.bool,
};

MbappOrganigrama.defaultProps = {
  label: "",
  placeholder: "",
  required: false,
};

export default MbappOrganigrama;
