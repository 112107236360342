import React from "react";
import PropTypes from "prop-types";
import Icon from "../mbapp-icons/mbapp-icon";
import "./mbapp-sidebar-item.css";

/**
 * Opción del menú del sidebar
 *
 * @version 1.0.1
 *
 */
const MbappSidebarItem = ({
  text,
  icon,
  iconSize,
  iconSrc,
  selected,
  noText,
}) => (
  <div className="sidebar-item">
    <div className="sidebar-item--selected"></div>
    <Icon name={icon} size={iconSize} />
    <img
      className="sidebar-item--avatar"
      src={iconSrc}
      width={iconSize}
      height={iconSize}
      alt=""
      style={{ display: iconSrc ? "inline" : "none" }}
    />
    <span className={noText ? "sidebar-item__no-text" : "sidebar-item__text"}>
      {text.length > 18 ? `${text.substring(0, 19)}…` : text}
    </span>
  </div>
);

MbappSidebarItem.propTypes = {
  /** Texto del item */
  text: PropTypes.string.isRequired,
  /** Nombre del ícono */
  icon: PropTypes.string,
  /** Usar una imagen en vez de icono */
  iconSrc: PropTypes.string,
  /** Tamaño del ícono */
  iconSize: PropTypes.number,
  /** Estado de seleccion */
  selected: PropTypes.bool,
  /** Estado de visibilidad del texto */
  noText: PropTypes.bool,
};

MbappSidebarItem.defaultProps = {
  text: "",
  icon: "",
  iconSrc: "",
  iconSize: 21,
  selected: false,
  noText: false,
};

export default MbappSidebarItem;
