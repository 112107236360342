import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Route, useLocation } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import InfoPage from "../mbapp-147-reclamo-info/mbapp-147-reclamo-info";

import ListadoAreas from "../mbapp-147-tipo_reclamo-listado-areas/mbapp-147-tipo_reclamo-listado-areas";
import AreasDetalle from "../mbapp-147-tipo_reclamo-detalle/mbapp-147-tipo_reclamo-detalle";

/**
 * Aplicación de tipo de reclamo
 *
 * @version 1.0.0
 *
 */
const Mbapp147TipoReclamoApp = () => {
  const queryClient = new QueryClient();
  const location = useLocation();
  const [showPanel, setShowPanel] = useState(false);

  useEffect(() => {
    if (location.pathname.startsWith("/tipo_reclamo/detalle/")) {
      setShowPanel(true);
    } else {
      setShowPanel(false);
    }
  }, [location]);

  return (
    <div className="app147">
      <div className="app147--panel app147--panel--reclamos">
        <Route path="/tipo_reclamo">
          <QueryClientProvider client={queryClient}>
            <ListadoAreas />
          </QueryClientProvider>
        </Route>
      </div>
      <div
        className={`app147--panel app147--panel--detalle ${
          showPanel ? "app147--panel--detalle__show" : ""
        }`}
      >
        <Route path="/tipo_reclamo/detalle/:idArea">
          <AreasDetalle titulo="Tipos de reclamo del área" />
        </Route>
        <Route exact path="/tipo_reclamo">
          <InfoPage
            text={"Seleccionar un área"}
            subtext={"para gestionar los tipos de reclamo"}
            icono="alertCircle"
          />
        </Route>
      </div>
    </div>
  );
};

Mbapp147TipoReclamoApp.propTypes = {
  /** Description */
  name: PropTypes.string.isRequired,
};

Mbapp147TipoReclamoApp.defaultProps = {
  name: "",
};

export default Mbapp147TipoReclamoApp;
