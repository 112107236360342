import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App147 from "./components/mbapp-147-app/mbapp-147-app";
import MbappSidebar from "./components/mbapp-sidebar/mbapp-sidebar";
import Login from "./components/react-mbapp-login/react-mbapp-login";
import SnackbarProvider from "react-simple-snackbar";

import reportWebVitals from "./reportWebVitals";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

import LandingPage from "./components/mbapp-147-landing/mbapp-147-landing";

import Notification from "./components/mbapp-system-notifications/mbapp-system-notifications.jsx";

import {
  ProvideAuth,
  useAuth,
} from "./components/mbapp-hook-auth/mbapp-hook-auth";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

const PrivateRoute = ({ children, ...rest }) => {
  const auth = useAuth();
  return (
    <Route
      {...rest}
      render={({ location }) =>
        auth.user ? (
          children
        ) : (
          <Redirect to={{ pathname: "/", state: { from: location } }} />
        )
      }
    />
  );
};

ReactDOM.render(
  <React.StrictMode>
    <Notification />
    <Router>
      <ProvideAuth>
        <Switch>
          <Route exact path="/login">
            <Login />
          </Route>
          <Route exact path="/">
            <LandingPage />
          </Route>
          <PrivateRoute path="/">
            <SnackbarProvider>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <MbappSidebar appName="147" />
                <div style={{ width: "100%" }}>
                  <App147 />
                </div>
              </div>
            </SnackbarProvider>
          </PrivateRoute>
        </Switch>
      </ProvideAuth>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);

serviceWorkerRegistration.register();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
