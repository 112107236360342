import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import ComboBox from "../mbapp-combobox/mbapp-combobox";
import TextBox from "../mbapp-textbox/mbapp-textbox";

import { localidades } from "../../utils/utils.js";
import axios from "axios";

/**
 * Formulario de carga / edición de persona
 *
 * @version 1.0.0
 *
 */
const MbappPersonaForm = ({ getForm, persona, errores }) => {
  const [form, setForm] = useState({});
  const [dniDisabled, setDniDisabled] = useState(false);
  const [loadingCalles, setLoadingCalles] = useState(false);
  const [calleDisabled, setCalleDisabled] = useState(false);
  const [listaCalles, setListaCalles] = useState([]);

  const [errDni, setErrDni] = useState("");
  const [errNombre, setErrNombre] = useState("");
  const [errApellido, setErrApellido] = useState("");
  const [errCalle, setErrCalle] = useState("");

  // Cargar o resetear el formulario
  // los campos son los mismos que devuelve la API del CORE
  useEffect(() => {
    setDniDisabled(persona.dni ? true : false);

    // Resetear errores
    setErrDni("");
    setErrNombre("");
    setErrApellido("");
    setErrCalle("");

    // Setear el formulario
    setForm({
      dni: persona.dni ? persona.dni : "",
      nombre: persona.nombre ? persona.nombre : "",
      apellido: persona.apellido ? persona.apellido : "",
      fechaNacimiento: persona.fechaNacimiento
        ? new Date(persona.fechaNacimiento)
        : "",
      localidad: persona.localidad
        ? persona.localidad
        : "SAN CARLOS DE BOLIVAR",
      calle: persona.calle ? persona.calle : "",
      calleNumero: persona.calleNumero ? persona.calleNumero : "",
      piso: persona.piso ? persona.piso : "",
      depto: persona.depto ? persona.depto : "",
      codigoTelCelular: persona.codigoTelCelular
        ? persona.codigoTelCelular
        : "2314",
      telCelular: persona.telCelular ? persona.telCelular : "",
      codigoTelFijo: persona.codigoTelFijo ? persona.codigoTelFijo : "2314",
      telFijo: persona.telFijo ? persona.telFijo : "",
      email: persona.email ? persona.email : "",
      sexo: persona.sexo ? persona.sexo : "MASCULINO",
      extranjero: persona.extranjero ? persona.extranjero : "NO",
      ocupacion: persona.ocupacion ? persona.ocupacion : "",
    });
  }, [persona]);

  // Buscar calles
  async function handleDomicilio(e) {
    const calleQuery = e;
    handleChange("calle", calleQuery);

    try {
      setLoadingCalles(true);
      const res = await axios.get(
        `${process.env.REACT_APP_API_CORE_ENDPOINT}/calles/nombre/${calleQuery}?localidad=${form.localidad.id}`,
        {
          headers: {
            key: process.env.REACT_APP_CORE_KEY, // Cambiar en producción
          },
        }
      );

      if (res.status === 200) {
        setLoadingCalles(false);
        setErrCalle("");
        return setListaCalles(res.data);
      }
    } catch (err) {
      if (e !== "N/A") {
        setErrCalle("La calle no existe");
      }
      setLoadingCalles(false);
      return setListaCalles(["N/A"]);
    }
  }

  // Manejar las localidades
  const localidadHandler = (loc) => {
    switch (loc.id) {
      case "06105060":
        handleChange("calle", "");
        setCalleDisabled(false);
        break;
      case "06105070":
      case "06105050":
      case "06105010":
      case "06105020":
        handleChange("calle", "");
        setCalleDisabled(false);
        break;
      default:
        handleChange("calle", "N/A");
        setCalleDisabled(true);
    }

    setErrCalle("");
    handleChange("localidad", loc);
  };

  // Actualiza el estado del formulario
  const handleChange = (field, val) => {
    setForm((prevState) => ({
      ...prevState,
      [field]: val,
    }));
  };

  // Levanta el estado al parent
  getForm(form);

  useEffect(() => {
    let mensaje = "El campo no puede estar vacío";
    errores.forEach((err) => {
      switch (err) {
        case "dni":
          setErrDni(!form.dni ? mensaje : "");
          break;
        case "nombre":
          setErrNombre(!form.nombre ? mensaje : "");
          break;
        case "apellido":
          setErrApellido(!form.apellido ? mensaje : "");
          break;
        case "calle":
          setErrCalle(!form.calle ? mensaje : "");
          break;
        default:
          setErrDni(!form.dni ? mensaje : "");
          setErrNombre(!form.nombre ? mensaje : "");
          setErrApellido(!form.apellido ? mensaje : "");
          setErrCalle(!form.calle ? mensaje : "");
      }
    });
  }, [errores, form.dni, form.nombre, form.apellido, form.calle]);

  return (
    <form className="mbapp-form mbapp-persona-form">
      <div className="row">
        <p className="section-title">Datos personales</p>
      </div>
      <div className="row">
        <TextBox
          name="dni"
          label="DNI"
          type="numeric"
          size="8"
          enterhint="next"
          placeholder="Ingresar el número de documento"
          required
          disabled={dniDisabled}
          onChange={(value) => handleChange("dni", value)}
          value={form.dni}
          errorState={errDni}
        />
      </div>
      <div className="row">
        <TextBox
          name="frmNombre"
          label="Nombre"
          type="text"
          enterhint="next"
          placeholder="Ingresar el nombre"
          required
          onChange={(value) => handleChange("nombre", value)}
          value={form.nombre}
          errorState={errNombre}
        />

        <TextBox
          name="frmApellido"
          label="Apellido"
          type="text"
          enterhint="next"
          placeholder="Ingresar el apellido"
          required
          onChange={(value) => handleChange("apellido", value)}
          value={form.apellido}
          errorState={errApellido}
        />
      </div>

      <div className="row">
        <TextBox
          name="fechaNacimiento"
          label="Fecha nacimiento"
          type="date"
          enterhint="next"
          placeholder="La fecha de nacimiento"
          onChange={(value) => handleChange("fechaNacimiento", value)}
          value={form.fechaNacimiento}
        />
      </div>
      <div className="row">
        <p className="section-title">Domicilio</p>
      </div>

      <div className="row">
        <ComboBox
          name="localidad"
          text="Localidad"
          items={localidades}
          required
          onChange={(value) => localidadHandler(value)}
          value={form.localidad}
          textFieldName="nombre"
          addNA
        ></ComboBox>

        <TextBox
          name="frmCalle"
          label="Calle"
          type="text"
          enterhint="next"
          placeholder="Nombre de la calle"
          datalist={listaCalles}
          loading={loadingCalles}
          required
          disabled={calleDisabled}
          onChange={(value) => handleDomicilio(value)}
          value={form.calle}
          errorState={errCalle}
        />
      </div>
      <div className="row">
        <TextBox
          name="frmCasaNumero"
          label="Casa número"
          type="number"
          enterhint="next"
          placeholder="Número de la casa"
          onChange={(value) => handleChange("calleNumero", value)}
          value={form.calleNumero}
        />

        <TextBox
          name="frmCasaPiso"
          label="Piso"
          type="number"
          enterhint="next"
          placeholder="Piso depto."
          onChange={(value) => handleChange("piso", value)}
          value={form.piso}
        />

        <TextBox
          name="frmCasaDepto"
          label="Depto."
          type="text"
          enterhint="next"
          placeholder="Departamento"
          onChange={(value) => handleChange("depto", value)}
          value={form.depto}
        />
      </div>
      <div className="row">
        <p className="section-title">Contacto</p>
      </div>
      <div className="row">
        <TextBox
          name="caracteristicaTelCel"
          label="Código área"
          type="numeric"
          size="4"
          enterhint="next"
          placeholder="Ingresar la código de área sin el 0"
          onChange={(value) => handleChange("codigoTelCelular", value)}
          value={form.codigoTelCelular}
        />
        <TextBox
          name="telCelular"
          label="Teléfono celular"
          type="numeric"
          size="8"
          enterhint="next"
          placeholder="Número de celular sin el 15"
          onChange={(value) => handleChange("telCelular", value)}
          value={form.telCelular}
        />
      </div>
      <div className="row">
        <TextBox
          name="caracteristicaTelFijo"
          label="Código área"
          type="numeric"
          size="4"
          enterhint="next"
          placeholder="Código de área sin el 0"
          onChange={(value) => handleChange("codigoTelFijo", value)}
          value={form.codigoTelFijo}
        />
        <TextBox
          name="telFijo"
          label="Teléfono alternativo"
          type="numeric"
          size="8"
          enterhint="next"
          placeholder="Si es celular, sin 15"
          onChange={(value) => handleChange("telFijo", value)}
          value={form.telFijo}
        />
      </div>
      <div className="row">
        <TextBox
          name="email"
          label="Dirección de email"
          type="email"
          enterhint="next"
          placeholder="Ingresar la dirección de correo electrónico"
          onChange={(value) => handleChange("email", value)}
          value={form.email}
        />
      </div>
      <div className="row">
        <p className="section-title">Varios</p>
      </div>
      <div className="row">
        <ComboBox
          name="sexo"
          text="Sexo"
          items={[
            { id: "MASCULINO", nombre: "MASCULINO" },
            { id: "FEMENINO", nombre: "FEMENINO" },
            { id: "OTRO", nombre: "OTRO" },
          ]}
          onChange={(value) => handleChange("sexo", value)}
          value={form.sexo}
        ></ComboBox>
        <ComboBox
          name="extranjero"
          text="Extranjero naturalizado"
          items={[
            { id: "NO", nombre: "NO" },
            { id: "SI", nombre: "SI" },
          ]}
          onChange={(value) => handleChange("extranjero", value)}
          value={form.extranjero}
        ></ComboBox>
      </div>
      <div className="row">
        <TextBox
          name="ocupacion"
          label="Ocupación"
          type="text"
          enterhint="next"
          placeholder="Ingresar la tarea laboral que desarrolla"
          onChange={(value) => handleChange("ocupacion", value)}
          value={form.ocupacion}
        />
      </div>
    </form>
  );
};

MbappPersonaForm.propTypes = {
  /** Persona a cargar en el formulario */
  persona: PropTypes.object,
  /** Array de objetos de error */
  errores: PropTypes.arrayOf(PropTypes.object),
  /** Funcion para levantar el estado al parent */
  getForm: PropTypes.func.isRequired,
};

MbappPersonaForm.defaultProps = {
  persona: {},
  errores: [],
  getForm: null,
};

export default MbappPersonaForm;
