import React, { useState } from "react";
import PropTypes from "prop-types";
import Toolbar from "../mbapp-147-toolbar/mbapp-147-toolbar";
import Message from "../mbapp-message-item/mbapp-message-item";
import Dropdown from "../mbapp-dropdown/mbapp-dropdown";
import EnviarMensaje from "../mbapp-147-enviar-mensaje/mbapp-147-enviar-mensaje";
import Loading from "../mbapp-loading/mbapp-loading";
import ErrorPage from "../mbapp-147-reclamo-info/mbapp-147-reclamo-info";
import Item from "../mbapp-menu-item/mbapp-menu-item";
import Modal from "../mbapp-modal/mbapp-modal";
import Permission from "../mbapp-147-permisos/mbapp-147-permisos";

import axios from "axios";
import { useQuery } from "react-query";
import { getUserData, formatFullDate } from "../../utils/utils.js";
import { useSnackbar } from "react-simple-snackbar";

import { useParams, useHistory } from "react-router-dom";

import "./mbapp-147-reclamo-detalle.css";

/**
 * Detalle del reclamo
 *
 * @version 1.0.0
 *
 */
const MbappReclamoDetalle = ({ client }) => {
  const history = useHistory();
  const [modalEliminarVisible, setModalEliminarVisible] = useState(false);
  const [modalCerrarReclamoVisible, setModalCerrarReclamoVisible] = useState(
    false
  );
  const [
    modalRechazarReclamoVisible,
    setModalRechazarReclamoVisible,
  ] = useState(false);
  const [mensajeIndex, setMensajeIndex] = useState(null);
  const [openSnackbar, closeSnackbar] = useSnackbar();

  let { idReclamo } = useParams();
  const { isLoading, error, data, refetch } = useQuery(
    `detalleReclamo${idReclamo}`,
    () =>
      axios(
        `${process.env.REACT_APP_API_147_ENDPOINT}/reclamos/detalle/${idReclamo}`,
        {
          headers: { Authorization: `Bearer ${getUserData("token")}` },
        }
      ),
    {
      refetchInterval: 10000,
      refetchIntervalInBackground: true,
    }
  );

  if (isLoading) return <Loading color="var(--color-verde)" />;
  if (error)
    return (
      <ErrorPage
        text={"Algo salió mal: " + error.message}
        subtext={"Vuelva a intentarlo más tarde"}
        icono="cloudLightning"
      />
    );

  const handleDelete = async () => {
    setModalEliminarVisible(false);
    axios
      .delete(
        `${process.env.REACT_APP_API_147_ENDPOINT}/reclamos/${idReclamo}`,
        { headers: { Authorization: `Bearer ${getUserData("token")}` } }
      )
      .then((res) => {
        if (res.status === 200) {
          openSnackbar("✔ ¡Reclamo eliminado!");
          client.invalidateQueries("listadoReclamos");
          history.push("/");
        } else {
          openSnackbar(
            "Hubo un error al eliminar el reclamo. Vuelva a intentarlo más tarde"
          );
        }
      });
  };

  const handleCerrarReclamo = () => {
    setModalCerrarReclamoVisible(false);
    sendCambioEstadoMensaje("cerrar");
  };

  const handleRechazarReclamo = () => {
    setModalRechazarReclamoVisible(false);
    sendCambioEstadoMensaje("rechazar");
  };

  const sendCambioEstadoMensaje = (estado) => {
    axios
      .post(
        `${process.env.REACT_APP_API_147_ENDPOINT}/mensajes/${estado}/${idReclamo}/${mensajeIndex}`,
        null,
        { headers: { Authorization: `Bearer ${getUserData("token")}` } }
      )
      .then((res) => {
        if (res.status === 200) {
          openSnackbar(
            `¡Solicitud ${estado === "rechazar" ? "rechazada" : "aceptada"}!`
          );
          client.invalidateQueries("listadoReclamos");
        } else {
          openSnackbar(
            "Hubo un problema al ejecutar la acción. Vuelva a intentarlo más tarde"
          );
        }
      })
      .catch((err) => {
        //console.log(err.response.data);
        openSnackbar(
          "Hubo un problema al ejecutar la acción. Vuelva a intentarlo más tarde"
        );
      });
  };

  const handleMessageAction = (selectedIndex, action) => {
    setMensajeIndex(selectedIndex);
    if (action === 1) {
      setModalRechazarReclamoVisible(true);
    } else {
      setModalCerrarReclamoVisible(true);
    }
  };

  return (
    <div>
      <Modal
        isVisible={modalEliminarVisible}
        type="warning"
        titulo="Eliminar reclamo"
        onAccept={handleDelete}
        onClose={() => setModalEliminarVisible(false)}
        botonAceptarText="Eliminar"
      >
        <p>¿Realmente desea eliminar definitivamente el reclamo?.</p>
      </Modal>

      <Modal
        isVisible={modalCerrarReclamoVisible}
        titulo="Estado de reclamo"
        onAccept={handleCerrarReclamo}
        onClose={() => setModalCerrarReclamoVisible(false)}
        botonAceptarText="Cerrar"
      >
        <p>¿Realmente desea dar por finalizado este reclamo?.</p>
      </Modal>

      <Modal
        isVisible={modalRechazarReclamoVisible}
        type="warning"
        titulo="Estado de reclamo"
        onAccept={handleRechazarReclamo}
        onClose={() => setModalRechazarReclamoVisible(false)}
        botonAceptarText="Rechazar"
      >
        <p>¿Realmente desea rechazar el cierre de este reclamo?.</p>
      </Modal>

      <div className="app147-reclamo-detalle">
        <Toolbar label={`Detalle del reclamo`} backTo="/reclamos">
          <Permission canView={["O", "U"]}>
            <div>
              <Dropdown
                notifications={[]}
                icon="moreVertical"
                iconSize={20}
                className="app147-reclamo--more-settings"
                width="9em"
              >
                <ul>
                  <li>
                    <Item
                      icon="edit"
                      text="Editar"
                      onClick={() =>
                        history.push(`/reclamos/editar/${idReclamo}`)
                      }
                    />
                    <Item
                      icon="trash"
                      text="Eliminar"
                      onClick={() => setModalEliminarVisible(true)}
                    />
                  </li>
                </ul>
              </Dropdown>
            </div>
          </Permission>
        </Toolbar>
        <div className="app147--panel--detalle-reclamo--container app147--panel--detalle-reclamo-comentarios--container">
          <div className="app147--panel--detalle-reclamo--header">
            <h5>
              <span>
                RECLAMO
                <br />
              </span>
              # {data.data.numero}
            </h5>
            <h1>{data.data.titulo}</h1>
            <ul>
              <li>
                <b>Fecha de ingreso:</b> {formatFullDate(data.data.createdAt)}
              </li>
              <li>
                <b>Prioridad: </b>
                {data.data.prioridad === "BAJA" && (
                  <span className="tag tag-baja">baja</span>
                )}
                {data.data.prioridad === "NORMAL" && (
                  <span className="tag tag-normal">normal</span>
                )}
                {data.data.prioridad === "MEDIA" && (
                  <span className="tag tag-media">media</span>
                )}
                {data.data.prioridad === "ALTA" && (
                  <span className="tag tag-alta">alta</span>
                )}
              </li>
              <li>
                <b>Estado:</b> {data.data.estado}
              </li>
            </ul>
            <h4>Descripción</h4>
            <p>{data.data.descripcion}</p>
            <h3>Ubicación del reclamo</h3>
            <ul>
              <li>
                <b>Localidad:</b> <span>{data.data.localidad.nombre}</span>
              </li>
              <li>
                <b>Barrio:</b> <span>{data.data.barrio.nombre}</span>
              </li>
              <li>
                <b>Dirección:</b>{" "}
                <span>
                  {data.data.calle.nombre} {data.data.casaNumero}
                  {data.data.casaPiso && ` - Piso ${data.data.casaPiso}`}
                  {data.data.casaDepartamento &&
                    ` - Depto. ${data.data.casaDepartamento}`}
                </span>
              </li>
            </ul>
            <div className="app147--panel--detalle-reclamo--header--solicitante">
              <h3>Datos del solicitante</h3>
              <h2>
                {data.data.persona.nombre} {data.data.persona.apellido}
              </h2>
              <p>
                {data.data.persona.calle && data.data.persona.calle}
                <br />
                {data.data.persona.localidad &&
                  data.data.persona.localidad.nombre}
              </p>
            </div>
          </div>
          <Permission canView={["O", "R"]}>
            <div className="app147--panel--detalle-reclamo--respuestas-container">
              <h2>Respuestas</h2>
              <ul>
                {data.data.respuestas.map((respuesta, index) => (
                  <Message
                    mensaje={respuesta}
                    index={index}
                    onAceptar={(selectedIndex) =>
                      handleMessageAction(selectedIndex, 0)
                    }
                    onRechazar={(selectedIndex) =>
                      handleMessageAction(selectedIndex, 1)
                    }
                  />
                ))}
              </ul>
              <EnviarMensaje refetch={refetch} />
            </div>
          </Permission>
        </div>
      </div>
    </div>
  );
};

MbappReclamoDetalle.propTypes = {
  /** Description */
  name: PropTypes.string.isRequired,
};

MbappReclamoDetalle.defaultProps = {
  name: "",
};

export default MbappReclamoDetalle;
