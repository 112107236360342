import React from "react";
import PropTypes from "prop-types";
import "./mbapp-loading.css";

/**
 * Animación de carga
 *
 * @version 1.0.0
 *
 */
const MbappLoading = ({ color }) => (
  <div className="lds-ring">
    <div style={{ borderTopColor: color }}></div>
    <div style={{ borderTopColor: color }}></div>
    <div style={{ borderTopColor: color }}></div>
    <div style={{ borderTopColor: color }}></div>
  </div>
);

MbappLoading.propTypes = {
  /** Color del loader */
  color: PropTypes.string.isRequired,
};

MbappLoading.defaultProps = {
  color: "var(--color-azul-secundario)",
};

export default MbappLoading;
