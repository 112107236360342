import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Icon from "../mbapp-icons/mbapp-icon";

import "./mbapp-toggle-button.css";

/**
 * Component description
 *
 * @see More info https://logico.ar/
 * @version 1.0.0
 *
 */
const ToggleButton = ({ handleToggle, label, activado }) => {
  return (
    <div className={`toggle-button ${activado ? "toggle-button__show" : ""}`}>
      <button
        type="button"
        className="btn btn-action btn-unlock"
        onClick={() => handleToggle(!activado)}
      >
        <Icon name="unlock" size={22} />
      </button>

      <button
        type="button"
        className="btn btn-action btn-lock"
        onClick={() => handleToggle(!activado)}
      >
        <Icon name="lock" size={22} />
      </button>

      <label>{label}</label>
    </div>
  );
};

ToggleButton.propTypes = {
  /** Funcion para pasar el estado del toggle al parent */
  handleToggle: PropTypes.func.isRequired,
  /** Nombre de la etiqueta a mostrar */
  label: PropTypes.string,
  /** Estado del toggle */
  status: PropTypes.bool,
};

ToggleButton.defaultProps = {
  label: "",
  status: false,
};

export default ToggleButton;
