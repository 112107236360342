import React, { useState } from "react";
import PropTypes from "prop-types";
import "./mbapp-sidebar.css";
import logo from "../../images/logo.png";
import LogoApp from "../../images/logo-147.svg";
import DefaultAvatar from "../../images/default-user.png";

import MenuItem from "../mbapp-sidebar-item/mbapp-sidebar-item";
import { NavLink, useLocation } from "react-router-dom";
import { logout, getUserData } from "../../utils/utils.js";

import Permission from "../mbapp-147-permisos/mbapp-147-permisos";

/**
 * Barra vertical para navegación dentro de la app.
 */
function MbappSidebar({ mode, appName }) {
  const [mobileMode, setMobileMode] = useState(
    window.innerWidth < 720 ? true : false
  );

  const [iconified, setIconified] = useState(false);
  const [collapsed, setCollapsed] = useState(true);

  let className = "sidebar";

  if (mobileMode) {
    className += " sidebar--mobile";
  }
  if (mobileMode && !collapsed) {
    className += " sidebar--mobile__show";
  }
  if (iconified && !mobileMode) {
    className += " sidebar__collapsed";
  }

  return (
    <div>
      <button
        className="hamburger"
        type="button"
        onClick={() =>
          mobileMode ? setCollapsed(!collapsed) : setIconified(!iconified)
        }
      >
        <span className="hamburger-box">
          <span className="hamburger-inner"></span>
        </span>
      </button>
      <div className={className}>
        <div className="overlay" onClick={() => setCollapsed(!collapsed)}></div>
        <div className="sidebar--inner">
          <div className="sidebar--inner--brand">
            <img src={LogoApp} alt="Línea 147" />
          </div>
          <ul className="sidebar--inner-title--menu">
            <li onClick={() => (mobileMode ? setCollapsed(!collapsed) : null)}>
              <NavLink to="/reclamos" activeClassName="sidebar-item__selected">
                <MenuItem
                  icon="inbox"
                  text="Bandeja de entrada"
                  noText={iconified}
                />
              </NavLink>
            </li>
            <Permission canView={["O"]}>
              <li
                onClick={() => (mobileMode ? setCollapsed(!collapsed) : null)}
              >
                <NavLink
                  to="/reportes"
                  activeClassName="sidebar-item__selected"
                >
                  <MenuItem icon="pie" text="Reportes" noText={iconified} />
                </NavLink>
              </li>
              <li
                onClick={() => (mobileMode ? setCollapsed(!collapsed) : null)}
              >
                <NavLink
                  to="/tipo_reclamo"
                  activeClassName="sidebar-item__selected"
                >
                  <MenuItem
                    icon="list"
                    text="Tipo de reclamo"
                    noText={iconified}
                  />
                </NavLink>
              </li>
            </Permission>
            <Permission>
              <li
                onClick={() => (mobileMode ? setCollapsed(!collapsed) : null)}
              >
                <NavLink
                  to="/administracion"
                  activeClassName="sidebar-item__selected"
                >
                  <MenuItem
                    icon="shield"
                    text="Administración"
                    noText={iconified}
                  />
                </NavLink>
              </li>
            </Permission>
          </ul>

          <a href="https://bolivar.gob.ar" target="_BLANK" rel="noreferrer">
            <img className="sidebar--logo" src={logo} alt="Bien Bolívar" />
          </a>

          <ul className="sidebar--user-menu">
            <li>
              <MenuItem
                iconSrc={DefaultAvatar}
                text={`${getUserData("nombre")}`}
                noText={iconified}
              />
            </li>
            <li onClick={logout}>
              <MenuItem icon="logOut" text="Cerrar sesión" noText={iconified} />
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

MbappSidebar.propTypes = {
  /** Modalidad del sidebar */
  mode: PropTypes.oneOf(["mobile", "desktop"]),
  /** Nombre de la aplicación */
  appName: PropTypes.string,
};

MbappSidebar.defaultProps = {
  mode: "desktop",
  appName: "",
};

export default MbappSidebar;
