import React, { useState } from "react";
import PropTypes from "prop-types";
import Pulsar from "../mbapp-pulsar/mbapp-pulsar";
import DefaultAvatar from "../../images/default-user.png";
import Permission from "../mbapp-147-permisos/mbapp-147-permisos";

import { formatFullDate } from "../../utils/utils.js";

import "./mbapp-message-item.css";

/**
 * Component description
 *
 * @version 1.0.0
 *
 */
const MbappMessageItem = ({ mensaje, index, onAceptar, onRechazar }) => {
  const renderPulsar = () => {
    if (mensaje && mensaje.solicitarCierre) {
      if (mensaje.solicitudCierraAceptada === false) {
        return <Pulsar tipo="rojo" texto="Cierre rechazado" direccion="left" />;
      } else {
        return <Pulsar texto="Solicitud de cierre" direccion="left" />;
      }
    }
  };

  const renderFooter = () => {
    if (mensaje && mensaje.solicitarCierre) {
      if (
        !(
          mensaje.solicitudCierraAceptada ||
          mensaje.solicitudCierraAceptada === false
        )
      ) {
        return (
          <div className="mbapp-message-item--footer">
            <button
              type="button"
              className="btn btn-default btn-ghost btn-ghost-primary btn-small"
              onClick={() => onAceptar(index)}
            >
              Aceptar
            </button>
            <button
              type="button"
              className="btn btn-default btn-small"
              onClick={() => onRechazar(index)}
            >
              Rechazar
            </button>
          </div>
        );
      }
    }
  };

  return (
    <li className="mbapp-message-item">
      <div className="mbapp-message-item--body">
        {renderPulsar()}
        <div className="mbapp-message-item--nombre">
          <div className="mbapp-message-item--avatar">
            <img alt="Imagen de perfil" src={DefaultAvatar} />
          </div>
          <h4>
            {mensaje.user?.nombre} {mensaje.user?.apellido}
            <br />
            <span>{formatFullDate(mensaje.fecha)}</span>
          </h4>
        </div>
        <p>{mensaje.detalle}</p>
        <Permission canView={["O"]}>{renderFooter()}</Permission>
      </div>
    </li>
  );
};

MbappMessageItem.propTypes = {
  /** El mensaje a mostrar */
  mensaje: PropTypes.object.isRequired,
};

MbappMessageItem.defaultProps = {
  mensaje: {},
};

export default MbappMessageItem;
