import React, { useState } from "react";
import PropTypes from "prop-types";
import Icon from "../mbapp-icons/mbapp-icon";
import "./mbapp-dropdown.css";

/**
 * Muestra un menú desplegable al hacer click
 *
 * @version 1.0.0
 *
 */
function MbappDropdown({
  className,
  icon,
  iconSrc,
  iconSize,
  name,
  text,
  showPosition,
  notifications,
  width,
  children,
}) {
  const [drop, setDrop] = useState(false);

  let dropdownClass = ` dropdown--menu`;

  if (drop) {
    dropdownClass += ` dropdown--menu__show dropdown--menu__show__${showPosition}`;
  }

  return (
    <div className={className}>
      <button
        title={name}
        className="dropdown"
        onClick={() => {
          setDrop(true);
        }}
        onBlur={() => {
          setDrop(false);
        }}
      >
        {notifications && (
          <div
            className="dropdown--notification-badge"
            style={{ display: notifications.length ? "block" : "none" }}
          >
            {notifications.length}
          </div>
        )}
        {icon && (
          <Icon
            style={{
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
              textAlign: "center",
            }}
            name={icon}
            size={iconSize}
          />
        )}
        {iconSrc && (
          <img src={iconSrc} width={iconSize} height={iconSize} alt="" />
        )}
        <label>{text}</label>
        <div className={dropdownClass} style={{ width: width }}>
          {children && children}
        </div>
      </button>
    </div>
  );
}

MbappDropdown.propTypes = {
  /** Nombre de la clase  */
  className: PropTypes.string,
  /** Nombre del icono a mostrar */
  icon: PropTypes.string,
  /** Url del la imagen a mostrar */
  iconSrc: PropTypes.string,
  /** Tamaño del icono */
  iconSize: PropTypes.number,
  /** Nombre del componente */
  name: PropTypes.string,
  /** Posicion del menu */
  showPosition: PropTypes.oneOf([
    "top-right",
    "top-left",
    "bottom-right",
    "bottom-left",
  ]),
  /** Listado de notificaciones */
  notifications: PropTypes.arrayOf(PropTypes.object),
  /** Ancho del menú */
  width: PropTypes.string,
};

MbappDropdown.defaultProps = {
  className: "",
  icon: "",
  iconSrc: "",
  iconSize: 12,
  name: "",
  showPosition: "bottom-left",
  notifications: [],
  width: "12em",
};

export default MbappDropdown;
