import React, { useState } from "react";
import PropTypes from "prop-types";
import "./mbapp-button.css";

/**
 * Botones con estilos para formularios, dialogos, con soporte para múltiples
 * tamaños y estados.
 */
function MbappButton({ className, children, type }) {
  let baseClass = `btn ${className}`;
  return (
    <button className={baseClass} type={type}>
      {children}
    </button>
  );
}

MbappButton.propTypes = {
  /** Nombre de la clase CSS */
  className: PropTypes.string,
  /** Componentes internos */
  children: PropTypes.object,
  /** Tipo de botón */
  type: PropTypes.oneOf(["button", "reset", "submit"]),
};

MbappButton.defaultProps = {
  className: "",
  children: null,
  type: "button",
};

export default MbappButton;
