import React, { useState, useEffect } from "react";
import ReclamoForm from "../mbapp-147-reclamo-form/mbapp-147-reclamo-form";
import ReclamoDetalle from "../mbapp-147-reclamo-detalle/mbapp-147-reclamo-detalle";
import ListadoReclamo from "../mbapp-147-listado-reclamos/mbapp-147-listado-reclamos";
import MbappTopbar from "../mbapp-topbar/mbapp-topbar";
import TipoReclamoApp from "../mbapp-147-tipo_reclamo-app/mbapp-147-tipo_reclamo-app";
import ReportesApp from "../mbapp-147-app-reportes/mbapp-147-app-reportes";
import ReclamoInfo from "../mbapp-147-reclamo-info/mbapp-147-reclamo-info";

import { Switch, Route, useLocation, useHistory } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";

import "./mbapp-147-app.css";

/**
 * Aplicación principal del 147
 *
 * @version 1.0.0
 *
 */
const Mbapp147App = () => {
  const queryClient = new QueryClient();
  const location = useLocation();
  const [showPanel, setShowPanel] = useState(false);
  const [searchQuery, setSearchQuery] = useState("?va=1");

  const [verAbiertos, setVerAbiertos] = useState(true);
  const [verCerrados, setVerCerrados] = useState(false);
  const [verEspera, setVerEspera] = useState(false);

  useEffect(() => {
    if (
      location.pathname.startsWith("/reclamos/nuevo") ||
      location.pathname.startsWith("/reclamos/editar") ||
      location.pathname.startsWith("/reclamos/detalle")
    ) {
      setShowPanel(true);
    } else {
      setShowPanel(false);
    }
  }, [location]);

  let history = useHistory();

  const runSearch = (val) => {
    history.push("/reclamos");
    if (val) {
      setSearchQuery(`?query=${val}`);
    } else {
      setSearchQuery(
        `?${verAbiertos ? "va=1&" : ""}${verCerrados ? "vc=1&" : ""}${
          verEspera ? "ve=1" : ""
        }`
      );
    }
    queryClient.invalidateQueries("listadoReclamos");
  };

  const handleView = (control, estado) => {
    let iQuery = "?";

    switch (control) {
      case "ABIERTO":
        setVerAbiertos(estado);
        iQuery += estado ? "va=1&" : "";
        iQuery += verCerrados ? "vc=1&" : "";
        iQuery += verEspera ? "ve=1&" : "";
        break;
      case "CERRADO":
        setVerCerrados(estado);
        iQuery += verAbiertos ? "va=1&" : "";
        iQuery += estado ? "vc=1&" : "";
        iQuery += verEspera ? "ve=1&" : "";
        break;
      default:
        setVerEspera(estado);
        iQuery += verAbiertos ? "va=1&" : "";
        iQuery += verCerrados ? "vc=1&" : "";
        iQuery += estado ? "ve=1&" : "";
        break;
    }
    setSearchQuery(iQuery);
  };

  return (
    <div style={{ width: "100%" }}>
      <MbappTopbar onSearch={(val) => runSearch(val)} />
      <Route path="/reclamos">
        <div className="app147">
          <div className="app147--panel app147--panel--reclamos">
            <Switch>
              <Route path="/reclamos">
                <QueryClientProvider client={queryClient}>
                  <ListadoReclamo
                    query={searchQuery}
                    client={queryClient}
                    verAbiertos={verAbiertos}
                    verCerrados={verCerrados}
                    verEspera={verEspera}
                    onEstadoChange={(control, estado) =>
                      handleView(control, estado)
                    }
                  />
                </QueryClientProvider>
              </Route>
            </Switch>
          </div>
          <div
            className={`app147--panel app147--panel--detalle ${
              showPanel ? "app147--panel--detalle__show" : ""
            }`}
          >
            <Switch>
              <Route path="/reclamos/nuevo">
                <ReclamoForm client={queryClient} titulo="Nuevo reclamo" />
              </Route>
              <Route path="/reclamos/detalle/:idReclamo">
                <QueryClientProvider client={queryClient}>
                  <ReclamoDetalle client={queryClient} />
                </QueryClientProvider>
              </Route>
              <Route path="/reclamos/editar/:idReclamo">
                <QueryClientProvider client={queryClient}>
                  <ReclamoForm client={queryClient} titulo="Editar reclamo" />
                </QueryClientProvider>
              </Route>
            </Switch>
          </div>
        </div>
      </Route>
      <Route exact path="/reportes">
        <ReportesApp />
      </Route>
      <Route path="/tipo_reclamo">
        <TipoReclamoApp />
      </Route>
      <Route exact path="/administracion">
        <ReclamoInfo
          icono="shield"
          text="Administración"
          subtext="Seleccioná o creá un reclamo"
        />
      </Route>
    </div>
  );
};

Mbapp147App.propTypes = {
  /** Description */
};

Mbapp147App.defaultProps = {};

export default Mbapp147App;
