import React, { useState } from "react";
import PropTypes from "prop-types";
import Icon from "../mbapp-icons/mbapp-icon";
import { useSnackbar } from "react-simple-snackbar";

import axios from "axios";
import { getUserData } from "../../utils/utils.js";

/**
 * Carga de nuevo reclamo
 *
 * @version 1.0.0
 *
 */
const Mbapp147TipoReclamoNuevo = ({ areaId, onNew }) => {
  const [nombre, setNombre] = useState("");
  const [openSnackbar, closeSnackbar] = useSnackbar();

  const handleChange = (e) => {
    setNombre(e.target.value);
  };

  const handleKeyUp = (e) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };

  const handleSubmit = () => {
    let data = {
      nombre: nombre,
      areaId: areaId,
    };
    axios
      .post(`${process.env.REACT_APP_API_147_ENDPOINT}/tipo_reclamo/`, data, {
        headers: { Authorization: `Bearer ${getUserData("token")}` },
      })
      .then((res) => {
        setNombre("");
        // Retornar la data del componente
        data._id = res.data;
        data.__v = 0;
        onNew(data);
        openSnackbar("✔ ¡Creado!");
      })
      .catch((err) => {
        openSnackbar("" + err);
        console.log(err);
      });
  };

  return (
    <div className="app147--tipo_reclamo--item">
      <input
        type="text"
        placeholder="Ingresar el nombre del tipo de reclamo"
        onChange={handleChange}
        onKeyUp={handleKeyUp}
        value={nombre}
      />
      <div className="app147--tipo_reclamo--item--action-container">
        <button
          type="button"
          className="app147--tipo_reclamo--btn-nuevo hint hint--top"
          aria-label="Agregar"
          onClick={handleSubmit}
        >
          <Icon name="plus" size={15} className="toolbar147--button--icon" />
        </button>
      </div>
    </div>
  );
};

Mbapp147TipoReclamoNuevo.propTypes = {
  /** Description */
  name: PropTypes.string.isRequired,
};

Mbapp147TipoReclamoNuevo.defaultProps = {
  name: "",
};

export default Mbapp147TipoReclamoNuevo;
