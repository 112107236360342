import React from "react";
import PropTypes from "prop-types";
import Icon from "../mbapp-icons/mbapp-icon";
import Pulsar from "../mbapp-pulsar/mbapp-pulsar";
import "../mbapp-147-reclamo-item/mbapp-147-reclamo-item.css";

import { formatFullDate } from "../../utils/utils.js";

/**
 * Item de reclamo
 *
 * @version 1.0.0
 *
 */
const Mbapp147ReclamoItem = ({ reclamo }) => (
  <div
    className="app147--reclamo-item"
    style={{ borderColor: reclamo.vencido ? "red" : null }}
  >
    {reclamo.prioridad === "ALTA" && (
      <div
        className="app147--reclamo-item--prioridad app147--reclamo-item--prioridad__alta hint--left"
        aria-label="Prioridad alta"
      >
        <Icon name="alertTriangle" size={15} />
        <span>alta</span>
      </div>
    )}
    {reclamo.prioridad === "MEDIA" && (
      <div
        className="app147--reclamo-item--prioridad app147--reclamo-item--prioridad__media hint--left"
        aria-label="Prioridad media"
      >
        <Icon name="alertOctagon" size={15} />
        <span>media</span>
      </div>
    )}
    {reclamo.prioridad === "BAJA" && (
      <div
        className="app147--reclamo-item--prioridad app147--reclamo-item--prioridad__baja hint--left"
        aria-label="Prioridad baja"
      >
        <Icon name="chevronsDown" size={15} />
        <span>baja</span>
      </div>
    )}
    <div className="app147--reclamo-item--header">
      <h5>
        <span style={{ color: "#888" }}>RECLAMO {reclamo.estado}</span>
        <br /># {reclamo.numero}
      </h5>
      <p className="app147--reclamo-item--footer--fecha">
        {formatFullDate(reclamo.createdAt)}
      </p>
      <h3>
        {reclamo.solicitarCierre && (
          <Pulsar tipo="verde" texto="a cerrar" direccion="left" />
        )}
        {reclamo.estado === "CERRADO" && (
          <span
            style={{
              color: "white",
              borderRadius: "50%",
              background: "green",
              padding: "var(--sp-chico) 6px",
              fontSize: ".5em",
              top: "-3px",
              position: "relative",
            }}
          >
            ✔
          </span>
        )}{" "}
        {reclamo.titulo}
      </h3>
      <div className="app-147--reclamo-item--asignacion">
        <p className="app147--reclamo-item--header--area">
          {reclamo.area && reclamo.area.nombre}
        </p>
        <p className="app147--reclamo-item--header--tipo">
          {reclamo.tipoReclamo && reclamo.tipoReclamo.nombre}
        </p>
      </div>
    </div>
    <div className="app147--reclamo-item--footer">
      <p className="app147--reclamo-item--footer--comentarios">
        <Icon name="messageSquare" size={16} />
        {reclamo.respuestas.length} comentarios
      </p>
    </div>
  </div>
);

Mbapp147ReclamoItem.propTypes = {
  /** El objeto reclamo */
  reclamo: PropTypes.object.isRequired,
};

Mbapp147ReclamoItem.defaultProps = {
  reclamo: {},
};

export default Mbapp147ReclamoItem;
