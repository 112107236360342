import React, { useState } from "react";

import Icon from "../mbapp-icons/mbapp-icon";
import ToggleButton from "../mbapp-toggle-button/mbapp-toggle-button";

import { getUserData } from "../../utils/utils.js";
import axios from "axios";
import { useParams } from "react-router-dom";

/**
 * Envío de mensajes de respuesta
 *
 * @version 1.0.0
 *
 */
const MbappEnviarMensaje = ({ refetch }) => {
  let { idReclamo } = useParams();
  const [mensaje, setMensaje] = useState("");
  const [rows, setRows] = useState(1);
  const [solicitarCierre, setSolicitarCierre] = useState(false);

  const handleChange = (e) => {
    const lines = (e.target.value.match(/\n/g) || []).length;
    setRows(lines + 1);
    setMensaje(e.target.value);
  };

  const toggleHandler = (status) => {
    setSolicitarCierre(status);
  };

  function enviarMensaje() {
    axios
      .post(
        `${process.env.REACT_APP_API_147_ENDPOINT}/mensajes/${idReclamo}`,
        { detalle: mensaje, solicitarCierre: solicitarCierre ? "1" : "0" },
        { headers: { Authorization: `Bearer ${getUserData("token")}` } }
      )
      .then((data) => {
        if (data.status === 200) {
          setSolicitarCierre(false);
          setMensaje("");

          // Refrescar el listado
          refetch();
        }
      });
  }

  console.log("SOLICITAR CIERRE", solicitarCierre);

  return (
    <div className="app147--panel--detalle-reclamo--respuestas-responder">
      <ToggleButton
        label="El reclamo se va cerrar"
        handleToggle={(toggleStatus) => toggleHandler(toggleStatus)}
        activado={solicitarCierre}
      />
      <div className="app147--panel--detalle-reclamo--input-container">
        <textarea
          placeholder="Ingresar tu comentario"
          rows={rows}
          cols="1"
          onChange={handleChange}
          value={mensaje}
        >
          {mensaje}
        </textarea>
      </div>
      <button
        type="button"
        className="btn btn-action btn-send"
        onClick={() => enviarMensaje()}
      >
        <Icon name="send" size={22} />
      </button>
    </div>
  );
};

export default MbappEnviarMensaje;
