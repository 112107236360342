import React, { useState } from "react";
import PropTypes from "prop-types";
import "./mbapp-apps-drawer.css";
import Avatar from "../../images/default-user.png";
/**
 * Component description
 *
 * @see More info https://logico.ar/
 * @version 1.0.0
 *
 */
const MbappAppsDrawer = ({ user }) => {
  const [apps] = useState(user.apps);
  return (
    <ul className="mbapp-app-drawer">
      <li className="mbapp-app-drawer--app-item">
        <a
          href="https://cuenta.app.bolivar.gob.ar"
          rel="noreferrer"
          target="_BLANK"
          title="Cuenta"
        >
          <img src={user.avatar ? user.avatar : Avatar} alt="icono" />
          <p>Cuenta</p>
        </a>
      </li>
      {apps.map((app) => {
        return (
          <li className="mbapp-app-drawer--app-item">
            <a
              href={app.url}
              rel="noreferrer"
              target="_BLANK"
              title={app.nombreOficial}
            >
              <img src="/logo192.png" alt="icono" />
              <p>{app.nombreOficial}</p>
            </a>
          </li>
        );
      })}
    </ul>
  );
};

MbappAppsDrawer.propTypes = {
  /** Description */
  user: PropTypes.object.isRequired,
};

MbappAppsDrawer.defaultProps = {
  user: null,
};

export default MbappAppsDrawer;
