import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import ComboBox from "../mbapp-combobox/mbapp-combobox";
import Organigrama from "../mbapp-organigrama/mbapp-organigrama";
import Loading from "../mbapp-loading/mbapp-loading";

import Toolbar from "../mbapp-147-toolbar/mbapp-147-toolbar";

import TextBox from "../mbapp-textbox/mbapp-textbox";
import Boton from "../mbapp-button/mbapp-button";
import PersonaForm from "../mbapp-147-persona/mbapp-147-persona";
import Permission from "../mbapp-147-permisos/mbapp-147-permisos";

import { estado, prioridad, localidades } from "../../utils/utils.js";

import axios from "axios";
import { getUserData } from "../../utils/utils.js";

import { useParams, useHistory } from "react-router-dom";
import { useSnackbar } from "react-simple-snackbar";

import "./mbapp-147-reclamo-form.css";

/**
 * Formulario de nuevo reclamo
 *
 * @version 1.3.0
 */
const MbappReclamoForm = ({ client, reclamo, titulo }) => {
  const history = useHistory();
  const [openSnackbar, closeSnackbar] = useSnackbar();

  const [form, setForm] = useState(
    reclamo
      ? reclamo
      : {
          localidad: { nombre: "SAN CARLOS DE BOLIVAR", id: "06105060" },
          estado: "ABIERTO",
          prioridad: "NORMAL",
        }
  );

  const [listaCalles, setListaCalles] = useState(null);
  const [listaBarrios, setListaBarrios] = useState(null);
  const [loadingCalles, setLoadingCalles] = useState(false);
  const [loadingBarrios, setLoadingBarrios] = useState(false);
  const [barrioDisabled, setBarrioDisabled] = useState(false);
  const [calleDisabled, setCalleDisabled] = useState(false);

  const [dni, setDni] = useState("");
  const [persona, setPersona] = useState(null);

  const [barrioError, setBarrioError] = useState("");
  const [calleError, setCalleError] = useState("");
  const [organigrama, setOrganigrama] = useState(null);
  const [tipoReclamo, setTipoReclamo] = useState(null);

  const [errDni, setErrDni] = useState("");
  const [errTitulo, setErrTitulo] = useState("");
  const [errDescripcion, setErrDescripcion] = useState("");
  const [errArea, setErrArea] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  let { idReclamo } = useParams();

  // Cargar los datos en el formulario en el caso de editar
  useEffect(() => {
    if (idReclamo) {
      setIsLoading(true);
      axios
        .get(
          `${process.env.REACT_APP_API_147_ENDPOINT}/reclamos/detalle/${idReclamo}`,
          {
            headers: { Authorization: `Bearer ${getUserData("token")}` },
          }
        )
        .then((data) => {
          setForm(data.data);
          setDni(data.data.dniPersona);
          setPersona(data.data.persona);
          setOrganigrama(data.data.area);
          setTipoReclamo(data.data.tipoReclamo);
          setIsLoading(false);
        })
        .catch((err) => {
          openSnackbar("Hubo un problema al cargar el reclamo: " + err.message);
        });
    }
  }, [reclamo, idReclamo]);

  if (isLoading) return <Loading color="var(--color-verde)" />;

  // Buscar calles
  async function handleDomicilio(e) {
    const calleQuery = e;
    handleChange("calle", calleQuery);

    try {
      setLoadingCalles(true);
      const res = await axios.get(
        `${process.env.REACT_APP_API_CORE_ENDPOINT}/calles/nombre/${calleQuery}?localidad=${form.localidad.id}`,
        {
          headers: {
            key: process.env.REACT_APP_CORE_KEY, // Cambiar en producción
          },
        }
      );

      if (res.status === 200) {
        setLoadingCalles(false);
        setCalleError("");
        return setListaCalles(res.data);
      }
    } catch (err) {
      if (e !== "N/A") {
        setCalleError("La calle no existe");
      }
      setLoadingCalles(false);
      return setListaCalles(["N/A"]);
    }
  }

  // Buscar barrios
  async function handleBarrio(e) {
    const barrioQuery = e;
    handleChange("barrio", barrioQuery);

    try {
      setLoadingBarrios(true);
      const res = await axios.get(
        `${process.env.REACT_APP_API_CORE_ENDPOINT}/barrios/nombre/${barrioQuery}?localidad=${form.localidad.id}`,
        {
          headers: {
            key: process.env.REACT_APP_CORE_KEY, // Cambiar en producción
          },
        }
      );

      if (res.status === 200) {
        setLoadingBarrios(false);
        setBarrioError("");
        return setListaBarrios(res.data);
      }
    } catch (err) {
      setLoadingBarrios(false);
      setBarrioError(e !== "N/A" ? "El barrio no existe" : "");
      return setListaBarrios(["N/A"]);
    }
  }

  const dniHandler = (data) => {
    setDni(data);
  };

  const localidadHandler = (loc) => {
    switch (loc.id) {
      case "06105060":
        handleChange("barrio", "");
        handleChange("calle", "");
        setCalleDisabled(false);
        setBarrioDisabled(false);
        break;
      case "06105070":
      case "06105050":
      case "06105010":
      case "06105020":
        handleChange("barrio", "N/A");
        handleChange("calle", "");
        setCalleDisabled(false);
        setBarrioDisabled(true);
        break;
      default:
        handleChange("barrio", "N/A");
        handleChange("calle", "N/A");
        setCalleDisabled(true);
        setBarrioDisabled(true);
    }

    setCalleError("");
    setBarrioError("");
    handleChange("localidad", loc);
  };

  // Actualiza el estado del formulario
  const handleChange = (field, val) => {
    setForm((prevState) => ({
      ...prevState,
      [field]: val,
    }));
  };

  // Buscar barrios
  async function handleSubmit(e) {
    e.preventDefault();

    // Cargar el resto de los campos
    let data = form;
    data.duenio = getUserData("_id");
    data.dniPersona = dni;
    data.area = organigrama;
    data.tipoReclamo = tipoReclamo;

    if (!validarForm()) {
      return;
    }

    if (!idReclamo) {
      // Crear reclamo
      axios
        .post(`${process.env.REACT_APP_API_147_ENDPOINT}/reclamos`, form, {
          headers: {
            Authorization: `Bearer ${getUserData("token")}`,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            openSnackbar("✔ ¡Reclamo creado!");
            client.invalidateQueries("listadoReclamos");
            history.push("/");
          }
        })
        .catch((err) => {
          openSnackbar(
            "Hubo un error al crear el reclamo. Vuelva a intentarlo más tarde"
          );
          //console.log(err.response.data);
        });
    } else {
      // Editar reclamo
      axios
        .patch(
          `${process.env.REACT_APP_API_147_ENDPOINT}/reclamos/${idReclamo}`,
          form,
          {
            headers: { Authorization: `Bearer ${getUserData("token")}` },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            openSnackbar("✔ ¡Reclamo editado!");
            client.invalidateQueries("listadoReclamos");
            history.push("/");
          }
        })
        .catch((err) => {
          openSnackbar(
            "Hubo un error al crear el reclamo. Vuelva a intentarlo más tarde"
          );
        });
    }
  }

  const validarForm = () => {
    let esValido = true;

    setErrDni("");
    if (form.dni === "") {
      setErrDni("El Nº de DNI es obligatorio");
      esValido = false;
    }

    setErrTitulo("");
    if (form.titulo === "") {
      setErrTitulo("El titulo es obligatorio");
      esValido = false;
    }

    setErrDescripcion("");
    if (form.descripcion === "") {
      setErrDescripcion("La descripción es obligatoria");
      esValido = false;
    }

    setErrArea("");
    if (!form.area || form.area === 0) {
      setErrArea("Debe seleccionar un área");
      esValido = false;
    }

    return esValido;
  };

  return (
    <div>
      <Toolbar label={titulo} backTo="/reclamos"></Toolbar>
      <div className="app147--panel--detalle-reclamo--container">
        <form className="reclamo-form" onSubmit={handleSubmit}>
          <Permission canView={["O"]}>
            <PersonaForm
              handleDni={(data) => dniHandler(data)}
              personaPrecargar={persona}
            />
          </Permission>
          <div className="reclamo-form--reclamo-container">
            <h2>Reclamo</h2>
            <TextBox
              name="titulo"
              label="Titulo"
              type="text"
              enterhint="next"
              placeholder="Título del reclamo"
              required
              onChange={(value) => handleChange("titulo", value)}
              errorState={errTitulo}
              value={form.titulo}
            />

            <TextBox
              name="descripcion"
              label="Descripción"
              enterhint="next"
              placeholder="Descripción del reclamo"
              multiline
              lines="8"
              required
              onChange={(value) => handleChange("descripcion", value)}
              errorState={errDescripcion}
              value={form.descripcion}
            />

            <h3 className="subtitulo">Ubicación</h3>

            <ComboBox
              name="localidad"
              text="Localidad"
              items={localidades}
              onChange={(loc) => localidadHandler(loc)}
              required
              value={form.localidad}
            ></ComboBox>

            <TextBox
              name="barrio"
              label="Barrio"
              type="text"
              enterhint="next"
              placeholder="Nombre del barrio"
              datalist={listaBarrios}
              loading={loadingBarrios}
              required
              disabled={barrioDisabled}
              errorState={barrioError}
              onChange={(value) => handleBarrio(value)}
              value={form.barrio && form.barrio.nombre}
            />

            <TextBox
              name="calle"
              label="Calle"
              type="text"
              enterhint="next"
              placeholder="Nombre de la calle"
              datalist={listaCalles}
              loading={loadingCalles}
              required
              disabled={calleDisabled}
              errorState={calleError}
              onChange={(value) => handleDomicilio(value)}
              value={form.calle && form.calle.nombre}
            />

            <TextBox
              name="casaNumero"
              label="Casa número"
              type="number"
              enterhint="next"
              placeholder="Número de la casa"
              onChange={(value) => handleChange("casaNumero", value)}
              value={form.casaNumero}
            />

            <TextBox
              name="casaPiso"
              label="Piso"
              type="number"
              enterhint="next"
              placeholder="Piso depto."
              onChange={(value) => handleChange("casaPiso", value)}
              value={form.casaPiso}
            />

            <TextBox
              name="casaDepto"
              label="Depto."
              type="text"
              enterhint="next"
              placeholder="Departamento"
              onChange={(value) => handleChange("casaDepartamento", value)}
              value={form.casaDepartamento}
            />
          </div>
          <Permission canView={["O"]}>
            <div className="reclamo-form--asignacion-container">
              <h3 className="subtitulo">Asignación</h3>
              <Organigrama
                label="Área"
                placeholder="Seleccionar un área municipal"
                required
                getAreaSeleccionada={(value) => setOrganigrama(value)}
                getTipoReclamo={(value) => setTipoReclamo(value)}
                errorState={errArea}
                value={organigrama}
                valueTipoReclamo={tipoReclamo}
              />

              <hr />
              <h3 className="subtitulo">Configuración</h3>
              <ComboBox
                text="Estado"
                items={estado}
                onChange={(value) => handleChange("estado", value.id)}
                value={form.estado}
              />
              <ComboBox
                text="Prioridad"
                items={prioridad}
                value={form.prioridad}
                onChange={(value) => handleChange("prioridad", value.id)}
              />
            </div>
          </Permission>

          <Boton type="submit" className="btn-pill btn-big">
            Guardar
          </Boton>
        </form>
      </div>
    </div>
  );
};

MbappReclamoForm.propTypes = {
  /** Description */
  name: PropTypes.string.isRequired,
};

MbappReclamoForm.defaultProps = {
  name: "",
};

export default MbappReclamoForm;
