import React from "react";
import PropTypes from "prop-types";
import { useAuth } from "../mbapp-hook-auth/mbapp-hook-auth.js";

import LandingHero from "../../images/hero.svg";
import LandingBgTopRight from "../../images/landing-bg2.svg";
import LandingBgBottomRight from "../../images/landing-bg3.svg";
import Logo from "../../images/logo-147.svg";

import { Redirect, Link } from "react-router-dom";

import "./mbapp-147-landing.css";

/**
 * Página de bienvenida
 *
 * @version 1.0.0
 *
 */
const Mbapp147Landing = () => {
  const auth = useAuth();

  if (auth.user) {
    return <Redirect to={{ pathname: "/reclamos", state: { from: "/" } }} />;
  }

  return (
    <div className="landing-147">
      <img className="landing-147--brand" src={Logo} alt="imagen de portada" />
      <main className="landing-147--container">
        <div>
          <img
            className="landing-147--hero"
            src={LandingHero}
            alt="imagen de portada"
            width="650"
            height="577"
            loading="lazy"
          />
        </div>
        <div>
          <h1>Hola, ¿en qué podemos ayudarte?</h1>
          <p>
            Te damos la bienvenida a la aplicación de atención al ciudadano del{" "}
            <b>
              <a
                href="https://bolivar.gob.ar"
                target="_blank"
                rel="noreferrer"
                style={{ color: "var(--color-verde)" }}
              >
                Partido de Bolívar.
              </a>
            </b>
          </p>
          <Link to="/login" className="btn btn-big btn-pill">
            ¡Comenzar!
          </Link>
        </div>
      </main>
      <div className="landing-147--bg">
        <img
          src={LandingBgTopRight}
          className="landing-147--bg_top-right"
          alt=""
          loading="lazy"
        />
        <img
          src={LandingBgBottomRight}
          className="landing-147--bg_bottom-right"
          alt=""
          loading="lazy"
        />
        <small>ver. {process.env.REACT_APP_VERSION}</small>
      </div>
    </div>
  );
};

Mbapp147Landing.propTypes = {
  /** Description */
  name: PropTypes.string.isRequired,
};

Mbapp147Landing.defaultProps = {
  name: "",
};

export default Mbapp147Landing;
