import React, { useState } from "react";
import PropTypes from "prop-types";
import "../mbapp-searchbar/mbapp-searchbar.css";
import "./mbapp-147-persona.css";
import Icon from "../mbapp-icons/mbapp-icon";
import Modal from "../mbapp-modal/mbapp-modal";
import PersonaForm from "../mbapp-persona-form/mbapp-persona-form";
import { useSnackbar } from "react-simple-snackbar";

//import { debounce } from "../../utils/utils.js";
import axios from "axios";

/**
 * Formulario de búsqueda de personas
 *
 * @version 1.0.0
 *
 */
const Mbapp147Persona = ({ handleDni, personaPrecargar }) => {
  const [persona, setPersona] = useState(
    personaPrecargar ? personaPrecargar : {}
  );
  const [esNuevaPersona, setEsNuevaPersona] = useState(
    personaPrecargar ? false : true
  );

  const [personaForm, setPersonaForm] = useState({});
  const [personaErr, setPersonaErr] = useState([]);
  const [dni, setDni] = useState(personaPrecargar ? personaPrecargar.dni : "");
  const [tituloModal, setTituloModal] = useState("");
  const [modalPersonaVisible, setModalPersonaVisible] = useState(false);
  const [openSnackbar, closeSnackbar] = useSnackbar();

  // Levantar el estado al parent
  handleDni(dni);

  const getPersona = (e) => {
    const dniQuery = e.target.value;
    setDni(dniQuery);
    if (dniQuery.length >= 6) {
      let headers = {
        key: process.env.REACT_APP_CORE_KEY, // Cambiar en producción
      };
      console.log(process.env.REACT_APP_CORE_KEY);
      axios
        .get(
          `${process.env.REACT_APP_API_CORE_ENDPOINT}/personas/${dniQuery}`,
          {
            headers: {
              key: process.env.REACT_APP_CORE_KEY, // Cambiar en producción
            },
          }
        )
        .then((apiResponse) => {
          if (apiResponse.status === 200) {
            setDni(apiResponse.data.dni);
            return setPersona(apiResponse.data);
          }
        })
        .catch((err) => {
          if (err.response?.status !== 404) {
            openSnackbar(
              "Hubo un problema al consultar el DNI: " + err.message
            );
          }
          return setPersona("");
        });
    } else {
      return setPersona("");
    }
  };

  // Abrir el formulario en modo nuevo
  const handleNuevaPersona = () => {
    setPersona({});
    setTituloModal("Cargar nueva persona");
    setModalPersonaVisible(true);
    setEsNuevaPersona(true);
  };

  // Abrir el formulario en modo edicion
  const handleEditarPersona = () => {
    setTituloModal("Editar persona");
    setModalPersonaVisible(true);
    setEsNuevaPersona(false);
  };

  // Guardar el estado en la DB
  const handleGuardarPersona = () => {
    if (validarFormularioPersona(personaForm)) {
      if (esNuevaPersona) {
        axios
          .post(
            `${process.env.REACT_APP_API_CORE_ENDPOINT}/personas/`,
            personaForm,
            {
              headers: {
                key: process.env.REACT_APP_CORE_KEY, // Cambiar en producción
              },
            }
          )
          .then((data) => {
            setPersona(personaForm);
            setModalPersonaVisible(false);
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        axios
          .patch(
            `${process.env.REACT_APP_API_CORE_ENDPOINT}/personas/${persona.dni}`,
            personaForm,
            {
              headers: {
                key: process.env.REACT_APP_CORE_KEY, // Cambiar en producción
              },
            }
          )
          .then((data) => {
            console.log(data.status);
            setPersona(personaForm);
            setModalPersonaVisible(false);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  };

  const validarFormularioPersona = (form) => {
    let errores = [];
    let mensaje = "El campo no puede estar vacío";

    if (form) {
      if (!form.dni) {
        errores.push({ campo: "dni", mensaje: mensaje });
      }

      if (!form.nombre) {
        errores.push({
          campo: "nombre",
          mensaje: mensaje,
        });
      }

      if (!form.apellido) {
        errores.push({
          campo: "apellido",
          mensaje: mensaje,
        });
      }

      if (!form.calle) {
        errores.push({
          campo: "calle",
          mensaje: mensaje,
        });
      }
    }

    if (errores.length > 0) {
      setPersonaErr(errores);
      return false;
    }

    return true;
  };

  return (
    <div className="app147--persona">
      <Modal
        isVisible={modalPersonaVisible}
        titulo={tituloModal}
        onAccept={handleGuardarPersona}
        onClose={() => setModalPersonaVisible(false)}
        botonAceptarText="Guardar"
      >
        <PersonaForm
          persona={persona}
          getForm={(form) => {
            setPersonaForm(form);
          }}
          errores={personaErr}
        />
      </Modal>

      <h2>Datos personales</h2>
      <div className="searchbar app147--persona--search">
        <input
          className="searchbar--desktop-input app147--persona--search--input"
          inputmode="numeric"
          pattern="[0-9]*"
          type="tel"
          placeholder="Ingresar el DNI"
          size="8"
          maxLength="8"
          onChange={getPersona}
          enterhint="next"
          required
          value={dni}
        />

        <button
          type="button"
          className="searchbar--search-button app147--persona--search--button searchbar--search-button__neutral"
          disabled={persona.dni ? true : false}
          style={{ background: persona.dni ? "var(--color-gris-claro)" : null }}
          onClick={() => {
            handleNuevaPersona();
          }}
        >
          <div className="hint hint--bottom" aria-label="Cargar persona">
            <Icon name="plus" size={24} />
          </div>
        </button>
      </div>
      {persona.dni && (
        <div className="app147--persona--datos">
          {persona.nombre && persona.apellido && (
            <h4>
              {persona.nombre} {persona.apellido}
            </h4>
          )}
          <p className="app147--persona--datos--domicilio">
            <span>
              {persona.calle ? persona.calle : null}
              {persona.calleNumero ? ` ${persona.calleNumero}` : null}
            </span>
            {persona.localidad && (
              <span>
                {persona.localidad.nombre
                  ? ` - ${persona.localidad.nombre}`
                  : null}
              </span>
            )}
          </p>
          <p className="app147--persona--datos--contacto">
            {persona.telFijo && (
              <a href={"tel:" + persona.codigoTelFijo + persona.telFijo}>
                <Icon name="phoneCall" size={20} />
                {persona.codigoTelFijo}
                {persona.telFijo}
              </a>
            )}
            {persona.telCelular && (
              <a href={"tel:" + persona.codigoTelCelular + persona.telCelular}>
                <Icon name="phoneCall" size={20} /> {persona.codigoTelCelular}
                {persona.telCelular}
              </a>
            )}
            {persona.email && (
              <a
                href={
                  "mailto:" +
                  persona.email +
                  "?subject=Centro%20de%20Atención%20al%20Ciudadano%20-%20Municipalidad%20de%20Bolívar"
                }
              >
                <Icon name="mail" size={20} /> {persona.email}
              </a>
            )}
          </p>
          <div className="app147--persona--datos--contacto--faltantes">
            <p>¿Faltan datos o son incorrectos?</p>
            <button
              className="btn btn-default btn-primary"
              onClick={() => {
                handleEditarPersona();
              }}
              type="button"
            >
              <Icon name="edit" size={12} />
              Editar persona
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

Mbapp147Persona.propTypes = {
  /** Callback al manejador de dni */
  handleDni: PropTypes.func,
};

Mbapp147Persona.defaultProps = {
  handleDni: null,
};

export default Mbapp147Persona;
