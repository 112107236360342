import React, { useState } from "react";
import PropTypes from "prop-types";
import "./mbapp-searchbar.css";

import Icon from "../mbapp-icons/mbapp-icon";

/**
 * Cuadro de búsqueda
 *
 * @version 1.0.0
 *
 */
const MbappSearchbar = ({ onSearch }) => {
  const [mobileMode, setMobileMode] = useState(
    window.innerWidth < 720 ? true : false
  );
  const [searchQuery, setSearchQuery] = useState("");
  const [showModal, setShowModal] = useState(false);

  const handleSearch = (value) => {
    let val = value.target.value;
    setSearchQuery(val);
    if (val === "") {
      onSearch("");
    }
  };

  const handleKeyUp = (e) => {
    if (e.key === "Enter") {
      setShowModal(false);
      onSearch(searchQuery);
    }
  };

  return (
    <div className={`searchbar ${showModal ? "searchbar__show" : ""}`}>
      <div className="searchbar--overlay">
        <div className="searchbar--container">
          <input
            className="searchbar--desktop-input"
            type="search"
            placeholder="Buscar tickets"
            onChange={(value) => handleSearch(value)}
            onKeyUp={handleKeyUp}
            value={searchQuery}
          />
          <button
            type="button"
            className="searchbar--search-button"
            onClick={() =>
              mobileMode ? setShowModal(true) : onSearch(searchQuery)
            }
          >
            <Icon name="search" size={24} />
          </button>
          <button
            type="button"
            className="btn btn-primary searchbar--search-button-mobile"
            onClick={() => setShowModal(false) || onSearch(searchQuery)}
          >
            {searchQuery === "" ? "Cerrar" : "Buscar"}
          </button>
        </div>
      </div>
    </div>
  );
};

MbappSearchbar.propTypes = {
  /** Description */
  name: PropTypes.string.isRequired,
  /** Modalidad que muestra el cuadro de búsqueda */
  mode: PropTypes.oneOf(["mobile", "desktop"]),
};

MbappSearchbar.defaultProps = {
  mode: "desktop",
  name: "",
};

export default MbappSearchbar;
