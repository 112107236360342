import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import TextBox from "../mbapp-textbox/mbapp-textbox";
import Button from "../mbapp-button/mbapp-button";
import "./mbapp-login.css";
import logo from "../../images/logo-bb.png";
import { useAuth } from "../mbapp-hook-auth/mbapp-hook-auth.js";
import { Redirect } from "react-router-dom";

/**
 * Formulario de login al sistema
 */
const ReactMbappLogin = ({ continuar, loginEndpoint }) => {
  const auth = useAuth();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(auth.loginError);

  useEffect(() => {});

  const handleSubmit = (e) => {
    e.preventDefault();

    let formData = {};

    let err = false;
    if (!username) {
      setError(true);
      err = true;
    }
    if (!password) {
      setError(true);
      err = true;
    }

    if (err) return null;

    formData.username = username;
    formData.password = password;
    formData.appID = "147";

    auth.signin(formData);
  };

  const handleUsername = (value) => {
    setUsername(value);
  };

  const handlePassword = (value) => {
    setPassword(value);
  };

  if (auth.user) {
    return (
      <Redirect to={{ pathname: "/reclamos", state: { from: "/login" } }} />
    );
  }

  return (
    <div className="mbappLogin">
      <div className="container">
        <div className="formulario">
          <header className="row">
            <img className="logo" src={logo} alt="Bien Bolívar" />
            <h1 className="titulo">Iniciar sesión</h1>
            <h4 className="subtitulo">
              con una sola cuenta accedes a todas tus aplicaciones.
            </h4>
          </header>
          <main className="row" style={{ marginTop: "2em" }}>
            <form id="auth" className="login" onSubmit={handleSubmit}>
              <TextBox
                name="username"
                label="Usuario"
                type="text"
                required={true}
                autocomplete="username"
                onChange={handleUsername}
              />
              <TextBox
                name="password"
                label="Contraseña"
                type="password"
                enterhint="done"
                required={true}
                autocomplete="current-password"
                onChange={handlePassword}
              />

              <div
                className="inputGroup text-center hidden "
                style={{ display: auth.loginError ? "block" : "none" }}
              >
                <small className="blk text-alert">
                  La contraseña es incorrecta. Vuelve a intentarlo o haz clic en
                  "¿Olvidaste la contraseña?" para restablecerla.
                </small>
                <a
                  className="blk"
                  href="https://login.app.bolivar.gob.ar"
                  target="_blank"
                  rel="noreferrer"
                >
                  ¿Olvidaste la contraseña?
                </a>
              </div>
              <Button type="submit" className="btn-big btn-pill">
                Iniciar
              </Button>
            </form>
          </main>
        </div>
      </div>
    </div>
  );
};

ReactMbappLogin.defaultProps = {
  continuar: "/",
  loginEndpoint: "http://localhost:3001/auth/",
};

ReactMbappLogin.propTypes = {
  /** URL a donde redirigir una vez logueado */
  continuar: PropTypes.string,
  /** URL a donde hacer el request de autenticación */
  loginEndpoint: PropTypes.string,
};

export default ReactMbappLogin;
