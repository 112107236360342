import React from "react";
import PropTypes from "prop-types";
import { getUserData } from "../../utils/utils";

/**
 * Component description
 *
 * @see More info https://logico.ar/
 * @version 1.0.0
 *
 */
const MbappPermisos = ({ children, canView }) => {
  const render = () => {
    return getUserData("rol") === "A" || canView.includes(getUserData("rol"))
      ? true
      : false;
  };
  return <wl>{render() && children}</wl>;
};

MbappPermisos.propTypes = {
  /** Description */
  children: PropTypes.object.isRequired,
  /** Listado de usuarios habilitados para ver el componente */
  canView: PropTypes.arrayOf(String).isRequired,
};

MbappPermisos.defaultProps = {
  children: null,
  canView: [""],
};

export default MbappPermisos;
