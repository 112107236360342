import React, { useState, useEffect } from "react";
import Icon from "../mbapp-icons/mbapp-icon";
import PropTypes from "prop-types";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from "date-fns/locale/es";

import "./mbapp-textbox.css";

registerLocale("es", es);

/**
 * Componente generico para ingreso textual.
 */
function MbappTextBox(props) {
  const [startDate, setStartDate] = useState(props.value);

  const handleChange = (e) => {
    if (props.type === "date") {
      setStartDate(e);
      props.onChange(e);
    } else {
      props.onChange && props.onChange(e.target.value);
    }
  };

  return (
    <div className="textbox inputGroup">
      {props.multiline && props.multiline && (
        <textarea
          value={props.value}
          placeholder={props.placeholder}
          autoComplete={props.autocomplete}
          rows={props.lines}
          style={{ borderColor: props.errorState ? "red" : null }}
          onChange={handleChange}
          disabled={props.disabled}
          required={props.required ? true : null}
        ></textarea>
      )}

      {props.type && props.type === "date" && (
        <DatePicker
          dateFormat="dd/MM/yyyy"
          selected={props.value}
          onChange={(date) => handleChange(date)}
          className="mbappDatePicker"
          locale="es"
          placeholderText={props.placeholder}
          showYearDropdown
        />
      )}

      {!props.multiline && props.type !== "date" && (
        <input
          value={props.value}
          list={"list-" + props.name}
          id={props.name}
          name={props.name}
          placeholder={props.placeholder}
          autoComplete={props.autocomplete}
          type={props.type}
          enterhint={props.enterhint}
          required={props.required ? true : null}
          onChange={handleChange}
          style={{ borderColor: props.errorState ? "red" : null }}
          min={props.min}
          max={props.max}
          disabled={props.disabled}
          pattern={props.type === "numeric" ? "[0-9]*" : null}
          size={props.size}
          maxLength={props.size}
        />
      )}
      {props.loading && (
        <Icon className="textbox--loader" name="loader" size={16} />
      )}
      <label htmlFor={props.name}>
        {props.required ? "*" : null} {props.label}
      </label>
      <small className="textbox--error-text">
        {props.errorState && props.errorState}
      </small>

      {props.datalist && (
        <datalist id={"list-" + props.name}>
          <option key="a" value="N/A" />
          {props.datalist.map((item, key) => (
            <option key={key} value={item.nombre} />
          ))}
        </datalist>
      )}
    </div>
  );
}

MbappTextBox.propTypes = {
  /** El nombre del textbox */
  name: PropTypes.string.isRequired,
  /** Texto de la etiqueta */
  label: PropTypes.string.isRequired,
  /** Texto dentro del cuadro de texto */
  placeholder: PropTypes.string,
  /** Indicado de autocompletado */
  autocomplete: PropTypes.oneOf([
    "''",
    "username",
    "current-password",
    "email",
  ]),
  /** Indicador de tipo de textbox */
  type: PropTypes.oneOf([
    "email",
    "number",
    "password",
    "search",
    "tel",
    "text",
    "url",
    "date",
  ]),
  /** Indicador de tecla enter (uso en móvil) */
  enterhint: PropTypes.string,
  /** Es campo requerido (agrega un asterisco) */
  required: PropTypes.bool,
  /** Estado de validación del componente. True tiene un error */
  errorState: PropTypes.string,
  /** Estado de carga */
  loading: PropTypes.bool,
  /** Si acepta múltiples líneas */
  multiline: PropTypes.bool,
  /** Cantidad de líneas que se muestran */
  lines: PropTypes.number,
  /** Valor mínimo que acepta */
  min: PropTypes.number,
  /** Valor máximo que acepta */
  max: PropTypes.number,
  /** Estado de edición del texto */
  enabled: PropTypes.bool,
};

MbappTextBox.defaultProps = {
  name: "TextBox",
  label: "TextBox",
  placeholder: "",
  autocomplete: "",
  type: "text",
  enterhint: "next",
  required: false,
  errorState: "",
  loading: false,
  multiline: false,
  lines: 10,
  min: 0,
  max: 9999,
  enabled: true,
};

export default MbappTextBox;
