import React from "react";
import PropTypes from "prop-types";

import "./mbapp-pulsar.css";

/**
 * Component description
 *
 * @see More info https://logico.ar/
 * @version 1.0.0
 *
 */
const MbappPulsar = ({ tipo, texto, direccion }) => {
  const clase = `mbapp-pulsar hint--${direccion} mbapp-pulsar__${tipo}`;
  return <div className={clase} aria-label={texto}></div>;
};

MbappPulsar.propTypes = {
  /** Tipo de pulsar */
  tipo: PropTypes.oneOf(["verde", "naranja", "rojo"]),
  /** Texto de tooltip */
  texto: PropTypes.string,
  /** Texto de tooltip */
  direccion: PropTypes.oneOf(["top", "bottom", "left", "right"]),
};

MbappPulsar.defaultProps = {
  tipo: "verde",
  text: "",
  direccion: "bottom",
};

export default MbappPulsar;
