import React from "react";
import PropTypes from "prop-types";
import Icon from "../mbapp-icons/mbapp-icon";
import "./mbapp-147-toolbar.css";
import { Link } from "react-router-dom";

/**
 * Component description
 *
 * @version 1.0.0
 *
 */
const Mbapp147Toolbar = ({ label, children, backTo }) => {
  return (
    <div className="toolbar147">
      {backTo && (
        <Link to={backTo}>
          <button className="btn btn-action">
            <Icon name="arrowLeft" size="22" />
          </button>
        </Link>
      )}
      <div>
        <label>{label}</label>
      </div>
      {children}
    </div>
  );
};

Mbapp147Toolbar.propTypes = {
  /** Texto a mostrar */
  label: PropTypes.string.isRequired,
  /** Mostrar el botón volver */
  backTo: PropTypes.string,
};

Mbapp147Toolbar.defaultProps = {
  label: "",
  back: "",
};

export default Mbapp147Toolbar;
