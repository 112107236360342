import React from "react";
import PropTypes from "prop-types";
import Icon from "../mbapp-icons/mbapp-icon";
import "./mbapp-menu-item.css";

/**
 * Component description
 *
 * @see More info https://logico.ar/
 * @version 1.0.0
 *
 */
const MbappMenuItem = ({ icon, text, onClick, isCheckbox, checkState }) => {
  const renderCheckbox = () => {
    if (isCheckbox) {
      return (
        <span>
          <Icon name={checkState ? "check" : ""} size={18} />
        </span>
      );
    } else {
      return icon ? (
        <span>
          <Icon name={icon} size={18} />
        </span>
      ) : null;
    }
  };

  return (
    <li className="menu-item" onClick={onClick}>
      {icon === "separator" ? (
        <span className="menu-item--separator">
          <hr />
        </span>
      ) : (
        <div>
          {renderCheckbox()}
          <span>{text}</span>
        </div>
      )}
    </li>
  );
};

MbappMenuItem.propTypes = {
  /** Texto del item */
  text: PropTypes.string.isRequired,
  /** Nombre del ícono a mostrar */
  icon: PropTypes.string,
  /** Función a ejecutar cuando se hace click */
  onClick: PropTypes.func,
  /** Indica si se debe tratar cómo un checkbox */
  isCheckbox: PropTypes.func,
  /**  Estado del check */
  checkState: PropTypes.bool,
};

MbappMenuItem.defaultProps = {
  text: "",
  icon: null,
  onClick: null,
  isCheckbox: false,
  isChecked: false,
};

export default MbappMenuItem;
