import React, { useState } from "react";
import PropTypes from "prop-types";
import Icon from "../mbapp-icons/mbapp-icon";
import Modal from "../mbapp-modal/mbapp-modal";
import { useSnackbar } from "react-simple-snackbar";

import "./mbapp-147-tipo_reclamo-item.css";

import axios from "axios";
import { getUserData } from "../../utils/utils.js";

/**
 * Component description
 *
 * @version 1.0.0
 *
 */
const Mbapp147TipoReclamoItem = ({ item, onRemove }) => {
  const [editMode, setEditMode] = useState(false);
  const [text, setText] = useState(item && item.nombre ? item.nombre : "");
  const [modalEliminarVisible, setModalEliminarVisible] = useState(false);

  const [openSnackbar, closeSnackbar] = useSnackbar();

  const handleEdit = () => {
    let em = editMode;
    setEditMode(!em);

    if (em) {
      let data = {
        nombre: text,
      };
      axios
        .patch(
          `${process.env.REACT_APP_API_147_ENDPOINT}/tipo_reclamo/${item._id}`,
          data,
          {
            headers: { Authorization: `Bearer ${getUserData("token")}` },
          }
        )
        .then(() => {
          openSnackbar("✔ ¡Guardado!");
        })
        .catch((err) => {
          openSnackbar("" + err);
        });
    }
  };

  const handleChange = (e) => {
    setText(e.target.value);
  };

  const handleDelete = () => {
    axios
      .delete(
        `${process.env.REACT_APP_API_147_ENDPOINT}/tipo_reclamo/${item._id}`,
        {
          headers: { Authorization: `Bearer ${getUserData("token")}` },
        }
      )
      .then(() => {
        setModalEliminarVisible(false);
        openSnackbar("Eliminado correctamente");
        onRemove(item);
      })
      .catch((err) => {
        setModalEliminarVisible(false);
        openSnackbar("" + err);
      });
  };

  return (
    <div>
      <Modal
        isVisible={modalEliminarVisible}
        type="warning"
        titulo="Eliminar tipo de reclamo"
        onAccept={handleDelete}
        onClose={() => setModalEliminarVisible(false)}
        botonAceptarText="Eliminar"
      >
        <p>¿Realmente desea eliminar definitivamente el tipo de reclamo?.</p>
      </Modal>
      <div className="app147--tipo_reclamo--item">
        <div className="app147--tipo_reclamo--item--action-container">
          <button
            type="button"
            className="hint hint--top"
            aria-label={editMode ? "Guardar" : "Editar"}
            onClick={() => handleEdit()}
            style={{
              background: editMode
                ? "var(--color-verde)"
                : "var(--color-gris-mas-claro)",
            }}
          >
            <Icon
              name={editMode ? "check" : "edit"}
              size={15}
              className="toolbar147--button--icon"
            />
          </button>
        </div>

        <input
          type="text"
          value={text}
          disabled={!editMode}
          onChange={handleChange}
        />
        <div className="app147--tipo_reclamo--item--action-container">
          <button
            type="button"
            className="hint hint--top"
            aria-label="Eliminar"
            onClick={() => setModalEliminarVisible(true)}
          >
            <Icon name="trash" size={15} className="toolbar147--button--icon" />
          </button>
        </div>
      </div>
    </div>
  );
};

Mbapp147TipoReclamoItem.propTypes = {
  /** Description */
  item: PropTypes.object.isRequired,
};

Mbapp147TipoReclamoItem.defaultProps = {
  item: {},
};

export default Mbapp147TipoReclamoItem;
