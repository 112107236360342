/**
 * Evita enviar múltiples llamadas a la API
 *
 * @param func: Callback a la function a la que hay que ejecutar
 * @param wait: tiempo en milisegundos antes de enviar el request
 * @param inmediate: Flag para enviar el request inmediatamente
 *
 */
import axios from "axios";

export function debounce(func, wait, immediate) {
  var timeout;

  return function executedFunction() {
    let context = this;
    let args = arguments;

    let later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };

    let callNow = immediate && !timeout;

    clearTimeout(timeout);

    timeout = setTimeout(later, wait);

    if (callNow) func.apply(context, args);
  };
}

// Estado del reclamo
export const estado = [
  { id: "ABIERTO", nombre: "ABIERTO" },
  { id: "EN ESPERA", nombre: "EN ESPERA" },
  { id: "CERRADO", nombre: "CERRADO" },
];

// Prioridad del reclamo
export const prioridad = [
  { id: "NORMAL", nombre: "NORMAL" },
  { id: "BAJA", nombre: "BAJA" },
  { id: "MEDIA", nombre: "MEDIA" },
  { id: "ALTA", nombre: "ALTA" },
];

// Localidades del partido de Bolívar
export const localidades = [
  { nombre: "SAN CARLOS DE BOLIVAR", id: "06105060" },
  { nombre: "URDAMPILLETA", id: "06105070" },
  { nombre: "PIROVANO", id: "06105050" },
  { nombre: "HALE", id: "06105010" },
  { nombre: "JUAN F. IBARRA", id: "06105020" },
  { nombre: "PAULA", id: "06105040" },
  { nombre: "VILLA LYNCH PUEYRREDON", id: "06105080" },
  { nombre: "VILLA SANZ", id: "06105082" },
  { nombre: "VALLIMANCA", id: "06105081" },
  { nombre: "UNZUE", id: "06105083" },
];

export function clearUserData() {
  window.localStorage.clear();
}

export function setUserData(apiResponse) {
  let storage = window.localStorage;
  storage.setItem("username", apiResponse.username);
  storage.setItem("nombre", apiResponse.nombre);
  storage.setItem("apellido", apiResponse.apellido);
  storage.setItem("token", apiResponse.token);
  storage.setItem("rol", apiResponse.rol);
  storage.setItem("apps", JSON.stringify(apiResponse.apps));
}

export const getUserData = (key) => {
  let storage = window.localStorage;
  if (key === "apps") {
    return JSON.parse(storage.getItem("apps"));
  } else {
    return storage.getItem(key);
  }
};

export const getLocalUser = () => {
  let localUser = {
    username: getUserData("username"),
    nombre: getUserData("nombre"),
    apellido: getUserData("apellido"),
    token: getUserData("token"),
    rol: getUserData("rol"),
    apps: getUserData("apps"),
  };

  return localUser.token ? localUser : null;
};

/**
 * Convierte la fecha formato DD-MM-YYYY hh:mm
 *
 */
export const formatFullDate = (fecha) => {
  let date = new Date(fecha);
  return (
    ("00" + date.getDate()).slice(-2) +
    "-" +
    ("00" + (date.getMonth() + 1)).slice(-2) +
    "-" +
    date.getFullYear() +
    " " +
    ("00" + (date.getHours() + 1)).slice(-2) +
    ":" +
    ("00" + (date.getMinutes() + 1)).slice(-2)
  );
};

export const logout = () => {
  fetch(`${process.env.REACT_APP_SSO_ENDPOINT}/auth/logout`, {
    method: "POST", // or 'PUT'
    headers: {
      Authorization: `Bearer ${getUserData("token")}`,
      appID: "147",
    },
  })
    .then((response) => {
      if (response.status === 200) {
        clearUserData();
        window.location.href = "/";
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const postNotification = (text) => {
  var img = "/logo192.png";
  var notification = new Notification("Línea 147", {
    body: text,
    icon: img,
  });
};
