import React from "react";
import PropTypes from "prop-types";
import Icon from "../mbapp-icons/mbapp-icon";
import "./mbapp-147-reclamo-info.css";

/**
 * Muestra información en el panel
 *
 * @version 1.0.0
 *
 */
const MbappReclamoInfo = ({ text, subtext, icono }) => (
  <div className="app147-reclamo-info">
    <Icon name={icono} size={42} />
    <h2>{text}</h2>
    <p>{subtext}</p>
  </div>
);

MbappReclamoInfo.propTypes = {
  /** Texto a mostrar */
  text: PropTypes.string,
  /** Icono a mostrar */
  icono: PropTypes.string,
};

MbappReclamoInfo.defaultProps = {
  text: "Información",
  icono: "alertTriangle",
};

export default MbappReclamoInfo;
