import React, { useState } from "react";
import PropTypes from "prop-types";
import Loading from "../mbapp-loading/mbapp-loading";
import ErrorPage from "../mbapp-147-reclamo-info/mbapp-147-reclamo-info";
import Toolbar from "../mbapp-147-toolbar/mbapp-147-toolbar";

import axios from "axios";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";

import "./mbapp-147-tipo_reclamo-listado-areas.css";

/**
 * Component description
 *
 * @version 1.0.0
 *
 */
const Mbapp147ListadoAreas = () => {
  const [areas, setAreas] = useState(null);
  const [busqueda, setBusqueda] = useState("");

  const { isLoading, error, data } = useQuery(
    "listadoReclamos",
    () =>
      axios(`${process.env.REACT_APP_API_CORE_ENDPOINT}/organigrama`, {
        headers: { key: process.env.REACT_APP_CORE_KEY },
      }),
    {
      onSuccess(data) {
        setAreas(data.data);
      },
      onError(err) {
        if (err.toString().includes("401")) {
          window.location.href = "/login";
        }
      },
    }
  );

  if (isLoading) return <Loading color="var(--color-verde)" />;

  if (error) {
    return (
      <ErrorPage
        text={"Algo salió mal: " + error.message}
        subtext={"Vuelva a intentarlo más tarde"}
        icono="cloudLightning"
      />
    );
  }

  const renderAreas = (listado) => {
    if (listado && listado.length > 0) {
      return listado.map((area) => (
        <Link to={`/tipo_reclamo/detalle/${area.id}`}>
          <p className="app147--areas--item">{area.nombre}</p>
        </Link>
      ));
    }
  };

  return (
    <div>
      <Toolbar label="Listado de áreas"></Toolbar>
      <div className="app147--panel--reclamos--container app147--panel--areas--container">
        <input
          className="app147--areas--search"
          type="search"
          placeholder="¿Qué área estás buscando?"
          onChange={(val) => setBusqueda(val.target.value)}
        />
        <div className="app147--panel--areas--container">
          {renderAreas(
            data.data.filter((item) =>
              item.nombre.includes(busqueda.toUpperCase())
            )
          )}
        </div>
      </div>
    </div>
  );
};

Mbapp147ListadoAreas.propTypes = {
  /** Description */
  name: PropTypes.string.isRequired,
};

Mbapp147ListadoAreas.defaultProps = {
  name: "",
};

export default Mbapp147ListadoAreas;
