import React, { PureComponent } from "react";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

import PropTypes from "prop-types";

import Toolbar from "../mbapp-147-toolbar/mbapp-147-toolbar";

import "./mbapp-147-app-reportes.css";

/**
 * Contenedor de gráficos de reporte
 *
 * @version 1.0.0
 *
 */
const Mbapp147AppReportes = ({ name }) => {
  const data = [
    {
      name: "Page A",
      uv: 4000,
      pv: 2400,
      amt: 2400,
    },
    {
      name: "Page B",
      uv: 3000,
      pv: 1398,
      amt: 2210,
    },
    {
      name: "Page C",
      uv: 2000,
      pv: 9800,
      amt: 2290,
    },
    {
      name: "Page D",
      uv: 2780,
      pv: 3908,
      amt: 2000,
    },
    {
      name: "Page E",
      uv: 1890,
      pv: 4800,
      amt: 2181,
    },
    {
      name: "Page F",
      uv: 2390,
      pv: 3800,
      amt: 2500,
    },
    {
      name: "Page G",
      uv: 3490,
      pv: 4300,
      amt: 2100,
    },
  ];
  return (
    <div style={{ overflowX: "hidden", maxWidth: "100%" }}>
      <Toolbar label="Reportes"></Toolbar>
      <div className="app147--panel--reportes--container app147--reportes">
        <div className="app147--widget app147--widget-12">
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              width={500}
              height={300}
              data={data}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="pv" fill="#8884d8" />
              <Bar dataKey="uv" fill="#82ca9d" />
            </BarChart>
          </ResponsiveContainer>
        </div>
        <div className="app147--widget app147--widget-4 app147--widget-l"></div>
        <div className="app147--widget app147--widget-4 app147--widget-m"></div>
        <div className="app147--widget app147--widget-4 app147--widget-r"></div>
      </div>
    </div>
  );
};

Mbapp147AppReportes.propTypes = {
  /** Description */
  name: PropTypes.string.isRequired,
};

Mbapp147AppReportes.defaultProps = {
  name: "",
};

export default Mbapp147AppReportes;
