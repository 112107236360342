import React, { useState } from "react";
import PropTypes from "prop-types";
import Dropdown from "../mbapp-dropdown/mbapp-dropdown";
import Searchbar from "../mbapp-searchbar/mbapp-searchbar";
import DefaultAvatar from "../../images/default-user.png";
import Item from "../mbapp-menu-item/mbapp-menu-item";
import { logout, getLocalUser } from "../../utils/utils.js";
import AppsDrawer from "../mbapp-apps-drawer/mbapp-apps-drawer";

import "./mbapp-topbar.css";

/**
 * Barra superior
 *
 * @version 1.1.0
 *
 */

const MbappTopbar = ({ sectionName, onSearch }) => {
  const [user] = useState(getLocalUser());

  const calculateSize = () => {
    let cantidadApps = user.apps.length;
    switch (cantidadApps) {
      case 1:
        return "12em";
      default:
        return "314px";
    }
  };

  return (
    <div>
      <div className="topbar">
        <div className="topbar--section-name">
          <h1>{sectionName}</h1>
        </div>
        <div className="topbar--searchArea">
          <Searchbar onSearch={(val) => onSearch(val)} />
        </div>
        <div className="topbar--iconArea">
          <Dropdown
            className="topbar--dropdown"
            icon="bell"
            iconSize={22}
            name="Notificaciones"
            showPosition="bottom-left"
            notifications={[]}
          />
          <Dropdown
            className="topbar--dropdown"
            icon="rect"
            iconSize={22}
            width={calculateSize()}
            name="Aplicaciones"
            showPosition="bottom-left"
          >
            <AppsDrawer user={user} />
          </Dropdown>
          <Dropdown
            className="topbar--dropdown topbar--avatar-menu"
            iconSrc={DefaultAvatar}
            iconSize={24}
            name="perfil"
            showPosition="bottom-left"
            notifications={[]}
          >
            <ul>
              <Item icon="edit" text="Gestionar cuenta" />
              <Item icon="separator" />
              <Item icon="logOut" text="Cerrar sesión" onClick={logout} />
            </ul>
          </Dropdown>
        </div>
      </div>
    </div>
  );
};

MbappTopbar.propTypes = {
  /** Nombre de la aplicación */
  sectionName: PropTypes.string.isRequired,
};

MbappTopbar.defaultProps = {
  sectionName: "",
};

export default MbappTopbar;
