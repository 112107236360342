import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Toolbar from "../mbapp-147-toolbar/mbapp-147-toolbar";
import TipoReclamoItem from "../mbapp-147-tipo_reclamo-item/mbapp-147-tipo_reclamo-item";
import NuevoTipoReclamo from "../mbapp-147-tipo_reclamo-nuevo/mbapp-147-tipo_reclamo-nuevo";
import Loading from "../mbapp-loading/mbapp-loading";
import InfoPage from "../mbapp-147-reclamo-info/mbapp-147-reclamo-info";

import axios from "axios";
import { Route, useParams } from "react-router-dom";
import { getUserData } from "../../utils/utils.js";

import "./mbapp-147-tipo_reclamo-detalle.css";

/**
 * Gestión de tipo de reclamo
 *
 * @version 1.0.0
 *
 */
const Mbapp147TipoReclamoDetalleArea = ({ titulo }) => {
  let { idArea } = useParams();

  const [listaTipos, setListaTipos] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  // Cargar los datos en el formulario en el caso de editar
  useEffect(() => {
    if (idArea) {
      setIsLoading(true);
      axios
        .get(
          `${process.env.REACT_APP_API_147_ENDPOINT}/tipo_reclamo/area/${idArea}`,
          {
            headers: { Authorization: `Bearer ${getUserData("token")}` },
          }
        )
        .then((data) => {
          setListaTipos(data.data);
          setIsLoading(false);
          setError("");
        })
        .catch((err) => {
          setIsLoading(false);
          setError(err.message);
        });
    }
  }, [idArea]);

  if (isLoading) return <Loading color="var(--color-verde)" />;
  if (error)
    return (
      <InfoPage
        text={"Ups.. ocurrió un problema"}
        subtext={error}
        icono="cloudLightning"
      />
    );

  const handleNuevo = (nuevoTipo) => {
    setListaTipos([...listaTipos, nuevoTipo]);
  };

  const handleRemove = (item) => {
    setListaTipos(listaTipos.filter((itm) => itm._id !== item._id));
  };

  return (
    <div>
      <Toolbar label={titulo} backTo="/tipo_reclamo"></Toolbar>
      <Route path="/tipo_reclamo/detalle/:idArea">
        <div className="app147--panel--tipo_reclamo--listado">
          <NuevoTipoReclamo areaId={idArea} onNew={(val) => handleNuevo(val)} />
          <hr
            style={{
              marginBottom: "var(--sp-grande)",
              border: "none",
              borderTop: "1px solid var(--color-azul)",
            }}
          />

          <div className="app147--panel--tipo_reclamo--listado-lista">
            {listaTipos &&
              listaTipos.map((item, key) => (
                <TipoReclamoItem
                  item={item}
                  key={key}
                  onRemove={handleRemove}
                />
              ))}
          </div>
        </div>
      </Route>
    </div>
  );
};

Mbapp147TipoReclamoDetalleArea.propTypes = {
  /** Titulo de la pantalla */
  titulo: PropTypes.string.isRequired,
};

Mbapp147TipoReclamoDetalleArea.defaultProps = {
  titulo: "",
};

export default Mbapp147TipoReclamoDetalleArea;
